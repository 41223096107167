import React from "react";
import NoRecordsFoundImage from "assets/images/no-records-found.png";
import { __ } from "services/Translator";

export default function NoRecordsFound(props) {
  const content = props.content ? props.content : __("Sorry, No records found");
  return (
    <div className="no_records_found" >
      {content}
    </div>
  );
}
