import React from "react";

export default function CheckBox({ children, type, ...rest }) {
  const newType = type ? type : "checkbox";

  var styles = {
    checkboxWithText: {
      display: "flex",
    },
    checkboxContainer: {
      marginTop: "-2px",
      marginRight: "10px",
    },
  };

  if (rest.direction === "rtl") {
    styles.checkboxContainer.marginRight = "unset";
    styles.checkboxContainer.marginLeft = "20px";
  }

  return (
    <div className="checking">
      <div className="checkbox_with_text" style={styles.checkboxWithText}>
        <div className="checkbox_container" style={styles.checkboxContainer}>
          <input id={rest.id} type={newType} {...rest} />
          <label for={rest.id}></label>
        </div>
        <div className="checkbox_text">{children}</div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
}
