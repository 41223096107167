import React from "react";

const InputBox = (props) => {
  var newProps = {
    ...props,
    onChange: (ev) => {
      if (props.onChange) {
        props.onChange(ev.target.value, ev);
      }
    },
  };

  return <input {...newProps} />;
};

export default InputBox;
