import React, { useState, useEffect } from "react";

import { __ } from "services/Translator";
import api from "services/api";
import { Modal } from "react-bootstrap";
import { isMobile } from "services/isMobile";
import { Link } from "react-router-dom";
import UserLeftArea from "elements/dashboard_elements/UserLeftArea";
import SelectBox from "elements/SelectBox";

export default function Withdraw(props) {
  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const userBalance = props.userBalance;

  const [country, setCountry] = useState(null);

  const [paymentSystem, setPaymentSystem] = useState("");
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [withdrawalWallet, setWithdrawalWallet] = useState("");
  const [withdrawalEmail, setWithdrawalEmail] = useState(userDetails?.email);
  const [withdrawalMethods, setWithdrawalMethods] = useState([]);
  const [withdrawalMethod, setWithdrawalMethod] = useState(null);
  const [withdrawalDetails, setWithdrawalDetails] = useState({});
  const [withdrawalConfirmationModal, setWithdrawalConfirmationModal] = useState(false);
  const [withdrawalError, setWithdrawalError] = useState(null);
  const [amountError, setAmountError] = useState(null);

  const [withdrawalOption, setWithdrawalOption] = useState("");

  const getWithdrawalMethods = async () => {
    // if (withdrawalAmount) {
    props.setSpinner(true);
    var response = await api.post("/withdraw/methods", {
      amount: withdrawalAmount,
      type: withdrawalOption,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      // console.log(response.data.methods.length);
      setWithdrawalMethods(response.data.methods);
      if (!response.data.methods.filter((method) => method.method_id === withdrawalMethod).length) {
        setWithdrawalMethod(null);
        if (response.data.methods.length === 1) {
          setWithdrawalMethod(response.data.methods[0].method_id);
        }
      }
      setWithdrawalDetails(response.data.details);
    }
    // }
  };

  const isWithdrawPossible = async () => {
    if (!withdrawalMethod) {
      setWithdrawalError(__("Please select a withdrwal method"));
    } else if (!withdrawalWallet && isWalletAddressRequired()) {
      setWithdrawalError(__("Please enter your wallet address"));
    } else {
      var response = await api.post("/is-withdraw-possible", {
        method: withdrawalMethod,
        accNumber: withdrawalWallet,
      });
      if (response.status === 200) {
        setWithdrawalError(null);
      } else {
        setWithdrawalError(response.data.message);
      }
    }
  };

  const isWithdrawPossibleDirect = async () => {
    if (!withdrawalMethod) {
      setWithdrawalError(__("Please select a withdrwal method"));
    } else {
      // var response = await api.post("/is-withdraw-possible", {
      //   method: withdrawalMethod,
      //   accNumber: withdrawalWallet,
      // });
      // if (response.status === 200) {
      //   setWithdrawalError(null);
      // } else {
      //   setWithdrawalError(response.data.message);
      // }
      setWithdrawalConfirmationModal(true);
    }
  };

  const showWithdrwalConfirmationDirect = (event) => {
    event.preventDefault();
    setWithdrawalError("");
    isWithdrawPossibleDirect();
  };

  const showWithdrwalConfirmation = (event) => {
    if (withdrawalMethod === "Praxis:Cashier") {
      event.preventDefault();
      setWithdrawalError("");
      setWithdrawalConfirmationModal(true);
      return;
    }
    event.preventDefault();
    isWithdrawPossible();
    setWithdrawalConfirmationModal(true);
  };
  const closeWithdrwalConfirmation = () => {
    setWithdrawalConfirmationModal(false);
  };

  const submitWithdraw = async () => {
    if (withdrawalError) {
      return;
    }
    props.setSpinner(true);
    var response = await api.post("/withdraw", {
      amount: withdrawalAmount,
      method: withdrawalMethod,
      accNumber: withdrawalWallet,
      email: withdrawalEmail,
      payRetailerAdditionalValues: payRetailerAdditionalValues,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      if (response.data.status === "success") {
        props.showAlert("Success!!", response.data.message);
        setWithdrawalAmount("");
        setWithdrawalMethod(null);
        setWithdrawalWallet("");
        getWithdrawalMethods();
        closeWithdrwalConfirmation();
        props.getUserBalance();
      } else if (response.data.status === "redirect") {
        window.location.href = response.data.redirectUrl;
      }
    } else {
      closeWithdrwalConfirmation();
      if (response.data.data && response.data.data.field && response.data.data.field == "amount") {
        setAmountError(response.data.message);
      } else {
        props.showAlert("Warning!!", response.data.message, true);
      }
    }
  };

  const isWalletAddressRequired = (method) => {
    var required = false;
    if (!method) {
      required = true;
    } else if (method === "SKRILL:WLT") {
      required = true;
    } else if (method.indexOf("CRYPTOPAY:") === 0) {
      required = true;
    } else if (method == "Interac") {
      required = true;
    } else if (method.indexOf("Counos:") === 0) {
      required = true;
    } else if (method.indexOf("COINSPAID:") === 0) {
      required = true;
    } else if (method.indexOf("WEBPAYMENT:") === 0) {
      required = true;
    }
    return required;
  };

  const getCountry = async () => {
    var response = await api.get("/profile");
    if (response.status == 200) {
      setCountry(response.data.playerCountry);
      console.log(response.data.playerCountry);
    }
  };

  useEffect(() => {
    getCountry();
  }, []);

  const prBanks = {
    BR: [
      { value: "", label: __("Select Bank") },
      { value: "001", label: "Banco do Brasil S.A." },
      { value: "341", label: "Banco Itaú S.A." },
      { value: "033", label: "Banco Santander (Brasil) S.A." },
      { value: "237", label: "Banco Bradesco S.A." },
      { value: "237", label: "Next Tecnologia e Serviços Digitais" },
      { value: "104", label: "Caixa Econômica Federal" },
      { value: "422", label: "Banco Safra S.A." },
      { value: "748", label: "Banco Cooperativo Sicredi S.A." },
      { value: "041", label: "Banco do Estado do Rio Grande do Sul S.A. BANRISUL" },
      { value: "208", label: "BANCO BTG PACTUAL S.A." },
      { value: "655", label: "Neon Pagamentos" },
      { value: "077", label: "Banco Inter S.A." },
      { value: "121", label: "Banco Agibank S.A." },
      { value: "212", label: "Banco Original S.A." },
      { value: "260", label: "Nubank" },
      { value: "336", label: "Banco C6 S.A." },
      { value: "413", label: "Banco BV (Before Banco Votorantim)" },
    ],
    CL: [
      { value: "", label: __("Select Bank") },
      { value: "012", label: "Banco Del Estado De Chile" },
      { value: "504", label: "Banco Bbva ( Bilbao Vizcaya Argentaria Chile) / Banco Bhif" },
      { value: "028", label: "Banco Bice" },
      { value: "055", label: "Banco Consorcio" },
      { value: "027", label: "Banco Corpbanca" },
      { value: "001", label: "Banco De Chile / Banco A. Edwards / Credichile / Citybank" },
      { value: "016", label: "Banco De Crédito E Inversiones (BCI) / Tbanc" },
      { value: "507", label: "Banco Del Desarrollo" },
      { value: "051", label: "Banco Falabella" },
      { value: "009", label: "Banco Internacional" },
      { value: "039", label: "Banco Itau Chile / Bank Boston" },
      { value: "053", label: "Banco Ripley" },
      { value: "057", label: "Banco París" },
      { value: "037", label: "Banco Santander – Santiago / Banco Santander / Banefe" },
      { value: "049", label: "Banco Security" },
      { value: "672", label: "Coopeuch" },
      { value: "031", label: "Hsbc Bank (Chile)" },
      { value: "014", label: "Scotiabank / Sud – Americano" },
    ],
    MX: [
      { value: "", label: __("Select Bank") },
      { value: "37006", label: "BANCOMEXT" },
      { value: "37009", label: "BANOBRAS" },
      { value: "37019", label: "BANJERCITO" },
      { value: "37135", label: "NAFIN" },
      { value: "37166", label: "BANSEFI" },
      { value: "37168", label: "HIPOTECARIA FED" },
      { value: "40002", label: "BANAMEX" },
      { value: "40012", label: "BBVA BANCOMER" },
      { value: "40014", label: "SANTANDER" },
      { value: "40021", label: "HSBC" },
      { value: "40030", label: "BAJIO" },
      { value: "40036", label: "INBURSA" },
      { value: "40042", label: "MIFEL" },
      { value: "40044", label: "SCOTIABANK" },
      { value: "40058", label: "BANREGIO" },
      { value: "40059", label: "INVEX" },
      { value: "40060", label: "BANSI" },
      { value: "40062", label: "AFIRME" },
      { value: "40072", label: "BANORTE" },
      { value: "40102", label: "ACCENDO BANCO" },
      { value: "40103", label: "AMERICAN EXPRESS" },
      { value: "40106", label: "BANK OF AMERICA" },
      { value: "40108", label: "MUFG" },
      { value: "40110", label: "JP MORGAN" },
      { value: "40112", label: "BMONEX" },
      { value: "40113", label: "VE POR MAS" },
      { value: "40124", label: "DEUSTCHE" },
      { value: "40126", label: "CREDIT SUISSE" },
      { value: "40127", label: "AZTECA" },
      { value: "40128", label: "AUTOFIN" },
      { value: "40129", label: "BARCLAYS" },
      { value: "40130", label: "COMPARTAMOS" },
      { value: "40131", label: "BANCO FAMSA" },
      { value: "40132", label: "MULTIVA BANCO" },
      { value: "40133", label: "ACTINVER" },
      { value: "40136", label: "INTERCAM BANCO" },
      { value: "40137", label: "BANCOPPEL" },
      { value: "40138", label: "ABC CAPITAL" },
      { value: "40140", label: "CONSUBANCO" },
      { value: "40141", label: "VOLKSWAGEN" },
      { value: "40143", label: "CIBANCO" },
      { value: "40145", label: "BBASE" },
      { value: "40147", label: "BANKAOOL" },
      { value: "40148", label: "PAGATODO" },
      { value: "40150", label: "INMOBILIARIO" },
      { value: "40151", label: "DONDE" },
      { value: "40152", label: "BANCREA" },
      { value: "40154", label: "BANCO FINTERRA" },
      { value: "40155", label: "ICBC" },
      { value: "40156", label: "SABADELL" },
      { value: "40157", label: "SHINHAN" },
      { value: "40158", label: "MIZUHO BANK" },
      { value: "40160", label: "BANCO S3" },
      { value: "90600", label: "MONEXCB" },
      { value: "90601", label: "GBM" },
      { value: "90602", label: "MASARI" },
      { value: "90605", label: "VALUE" },
      { value: "90606", label: "ESTRUCTURADORES" },
      { value: "90608", label: "VECTOR" },
      { value: "90613", label: "CBOLSA" },
      { value: "90616", label: "FINAMEX" },
      { value: "90617", label: "VALMEX" },
      { value: "90620", label: "PROFUTURO" },
      { value: "90630", label: "CB INTERCAM" },
      { value: "90631", label: "CI BOLSA" },
      { value: "90634", label: "FINCOMUN" },
      { value: "90636", label: "HDI SEGUROS" },
      { value: "90638", label: "AKALA" },
      { value: "90642", label: "REFORMA" },
      { value: "90646", label: "STP" },
      { value: "90648", label: "EVERCORE" },
      { value: "90652", label: "CREDICAPITAL" },
      { value: "90653", label: "KUSPIT" },
      { value: "90656", label: "UNAGRA" },
      { value: "90659", label: "ASP INTEGRA OPC" },
      { value: "90670", label: "CB LIBERTAD" },
      { value: "90677", label: "CAJA POP MEXICA" },
      { value: "90680", label: "CRISTOBAL COLON" },
      { value: "90683", label: "CAJA TELEFONIST" },
      { value: "90684", label: "TRANSFER" },
      { value: "90685", label: "FONDO (FIRA)" },
      { value: "90686", label: "INVERCAP" },
      { value: "90689", label: "FOMPED" },
      { value: "90902", label: "INDEVAL" },
      { value: "90903", label: "CoDi Valida" },
    ],
    CO: [
      { value: "", label: __("Select Bank") },
      { value: "0001", label: "BANCO DE BOGOTA" },
      { value: "0002", label: "BANCO POPULAR" },
      { value: "0006", label: "BANCO CORPBANCA COLOMBIA SA." },
      { value: "0007", label: "BANCOLOMBIA" },
      { value: "0009", label: "CITIBANK" },
      { value: "0012", label: "BANCO GNB SUDAMERIS" },
      { value: "0013", label: "BBVA COLOMBIA" },
      { value: "0019", label: "BANCO COLPATRIA" },
      { value: "0023", label: "BANCO DE OCCIDENTE" },
      { value: "0031", label: "BANCOLDEX" },
      { value: "0032", label: "BANCO BCSC SA (Banco Caja Social)" },
      { value: "0040", label: "BANCO AGRARIO" },
      { value: "0041", label: "JPMORGAN CORPORACION FINANCIERA" },
      { value: "0042", label: "BNP PARIBAS" },
      { value: "0051", label: "BANCO DAVIVIENDA S.A." },
      { value: "0052", label: "BANCO AV VILLAS" },
      { value: "0058", label: "BANCO PROCREDIT" },
      { value: "0060", label: "BANCO PICHINCHA" },
      { value: "0061", label: "BANCOOMEVA" },
      { value: "0063", label: "BANCO FINANDINA" },
      { value: "0065", label: "BANCO SANTANDER DE NEGOCIOS COLOMBIA SA" },
      { value: "0066", label: "BANCO COOPERATIVO COOPCENTRAL" },
      { value: "0083", label: "COMPENSAR" },
      { value: "0084", label: "GESTION CONTACTO" },
      { value: "0086", label: "ASOPAGOS" },
      { value: "0087", label: "FEDECAJAS" },
      { value: "0088", label: "SIMPLE S.A" },
      { value: "0089", label: "ENLACE OPERATIVO" },
      { value: "0283", label: "COOPERTIVA FINANCIERA ANTIOQUIA" },
      { value: "0289", label: "COTRAFA COOPERATIVA FINANCIERA" },
      { value: "0292", label: "CONFIAR" },
      { value: "0370", label: "COLTEFINANCIERA S.A" },
      { value: "0550", label: "DECEVAL" },
      { value: "0683", label: "DGCPTN" },
      { value: "0685", label: "DGCPTN * Sistema General de Regalias" },
      { value: "0121", label: "FINANCIERA JURISCOP S.A. Compañía de Financiamiento" },
      { value: "0064", label: "BANCO MULTIBANK S.A." },
      { value: "0067", label: "BANCO COMPARTIR S.A." },
      { value: "0062", label: "BANCO FALABELLA S.A." },
      { value: "0069", label: "SERFINANSA S.A." },
    ],
    EC: [
      { value: "", label: __("Select Bank") },
      { value: "0001", label: "BANCO AMAZONAS" },
      { value: "0002", label: "BANCO BOLIVARIANO" },
      { value: "0003", label: "BANCO CAPITAL" },
      { value: "0004", label: "BANCO CENTRAL DEL ECUADOR" },
      { value: "0005", label: "BANCO CITY BANK" },
      { value: "0006", label: "BANCO COFIEC" },
      { value: "0007", label: "BANCO COOPNACIONAL SA" },
      { value: "0008", label: "BANCO D MIRO SA" },
      { value: "0009", label: "BANCO DE FOMENTO" },
      { value: "0010", label: "BANCO DE GUAYAQUIL S.A" },
      { value: "0011", label: "BANCO DE LA PRODUCCION" },
      { value: "0012", label: "BANCO DE LOJA" },
      { value: "0013", label: "BANCO DEL AUSTRO" },
      { value: "0014", label: "BANCO DEL INSTITU ECUATORIANO DE SEGURIDAD SOCIAL" },
      { value: "0015", label: "BANCO DEL LITORAL S.A." },
      { value: "0016", label: "BANCO DEL PACIFICO" },
      { value: "0017", label: "BANCO DELBANK S.A." },
      { value: "0018", label: "BANCO ECUATORIANO DE LA VIVIENDA" },
      { value: "0019", label: "BANCO GENERAL RUMINAHUI S.A." },
      { value: "0020", label: "BANCO INTERNACIONAL" },
      { value: "0021", label: "BANCO MACHALA" },
      { value: "0022", label: "BANCO PARA LA ASISTENCIA COMUNITARIA FINCA S.A." },
      { value: "0023", label: "BANCO PICHINCHA C.A." },
      { value: "0024", label: "BANCO PROCREDIT S.A." },
      { value: "0025", label: "BANCO PROMERICA S.A." },
      { value: "0026", label: "BANCO SOLIDARIO" },
      { value: "0027", label: "BANCO SUDAMERICANO" },
      { value: "0028", label: "C DE A Y C EMPLEAD BANCAR DEL ORO LTDA" },
      { value: "0029", label: "C. A. Y C. INTERCULT. TARPUK RUNA LTDA." },
      { value: "0030", label: "C. DE A Y C 26 DE SEPTBRE LAZARO CONDO" },
      { value: "0031", label: "C. DE A Y C 4 DE OCT. SAN FCO. DE CHAMBO" },
      { value: "0032", label: "C. DE A Y C EDUCAD. DE ZAMORA CHINCHIPE" },
      { value: "0033", label: "C. DE A Y C. EDUCADORES DE EL ORO LTD" },
      { value: "0034", label: "C. DE A Y C. SAN MIGUEL DE PALLATANGA" },
      { value: "0035", label: "C. DE A. Y C COOPAC AUSTRO LTDA (MIESS)" },
      { value: "0036", label: "C. DE A. Y C. SAN MARTIN DE TISALEO LTDA." },
      { value: "0037", label: "C. DE AH. Y CREDITO LAS LAGUNAS (MIESS)" },
      { value: "0038", label: "C.PEQ.EMPRESA DE PASTAZA" },
      { value: "0039", label: "CACPECO LTDA" },
      { value: "0040", label: "CAJA DE A Y C JUVENTUD Y DESARROLLO" },
      { value: "0041", label: "CAJA DE A Y C NUESTRA SENORA DE LA MERCED" },
      { value: "0042", label: "CAJA DE AHORRO Y CREDITO EL INGENIO" },
      { value: "0043", label: "CAJA DE AHORRO Y CREDITO EL MANIZAL" },
      { value: "0044", label: "CAJA DE AHORRO Y CREDITO FRANCISCA CHIGUA" },
      { value: "0045", label: "CAJA DE AHORRO Y CREDITO FRONTERA SUR" },
      { value: "0046", label: "CAJA DE AHORRO Y CREDITO HORIZONTE FAMILIAR" },
      { value: "0047", label: "CAJA DE AHORRO Y CREDITO MANU" },
      { value: "0048", label: "CAJA DE AHORRO Y CREDITO SAN FRANCISCO" },
      { value: "0049", label: "CAJA DE AHORRO Y CREDITO ZHONDELEG" },
      { value: "0050", label: "CAJA SOLIDARIA DE A Y C CORDTUCH" },
      { value: "0051", label: "CAJA SOLIDARIA ESTRELLA DEL MUNDO" },
      { value: "0052", label: "CAMARA DE COMERCIO JOYA DE LOS SACHAS" },
      { value: "0053", label: "COMERCIAL DE MANABI" },
      { value: "0054", label: "COOP A Y C 20 FEBRERO LTDA" },
      { value: "0055", label: "COOP A Y C CARROCEROS DE TUNGURAHUA" },
      { value: "0056", label: "COOP A Y C ECUAFUTURO LTDA" },
      { value: "0057", label: "COOP A Y C ESC SUP POLITEC AGROP DE MANABI MANUEL" },
      { value: "0058", label: "COOP A Y C FOCLA" },
      { value: "0059", label: "COOP A Y C HUINARA LTDA" },
      { value: "0060", label: "COOP A Y C NUEVOS HORIZONTES EL ORO LTDA" },
      { value: "0061", label: "COOP A Y C PROFESIONALES DEL VOLANTE UNION LTDA" },
      { value: "0062", label: "COOP AHORRO Y CREDITO CAMARA COMERCIO DE AMBATO" },
      { value: "0063", label: "COOP AHORRO Y CREDITO CARIAMANGA LTDA" },
      { value: "0064", label: "COOP AHORRO Y CREDITO CONSTRC COMERCIO Y PRODUC" },
      { value: "0065", label: "COOP AHORRO Y CREDITO DE LA PEQ EMP CACPE YANZATZA" },
      { value: "0066", label: "COOP AHORRO Y CREDITO FUNDESARROLLO" },
      { value: "0067", label: "COOP AHORRO Y CREDITO MI TIERRA" },
      { value: "0068", label: "COOP AHORRO Y CREDITO MUSHUC RUNA LTDA" },
      { value: "0069", label: "COOP AHORRO Y CREDITO PADRE JULIAN LORENTE LTDA" },
      { value: "0070", label: "COOP AHORRO Y CREDITO PEQ EMPRESA GUALAQUIZA" },
      { value: "0071", label: "COOP CACIQUE GURITAVE" },
      { value: "0072", label: "COOP CREDIUNION" },
      { value: "0073", label: "COOP DE A Y C 16 DE JUNIO" },
      { value: "0074", label: "COOP DE A Y C 23 DE MAYO LTDA" },
      { value: "0075", label: "COOP DE A Y C ACCION TUNGURAHUA LTDA" },
      { value: "0076", label: "COOP DE A Y C BANOS LTDA" },
      { value: "0077", label: "COOP DE A Y C CAMARA DE COMERICO GONZANAMA" },
      { value: "0078", label: "COOP DE A Y C CASAG LTDA" },
      { value: "0079", label: "COOP DE A Y C CATAMAYO LTDA" },
      { value: "0080", label: "COOP DE A Y C COCA LTDA" },
      { value: "0081", label: "COOP DE A Y C CREDIAMIGO LTDA LOJA MIES" },
      { value: "0082", label: "COOP DE A Y C CREDISUR LTDA" },
      { value: "0083", label: "COOP DE A Y C DE LA PEQ EMPRESA CACPE MACARA" },
      { value: "0084", label: "COOP DE A Y C DE SERV PUBL DEL MIN EDUCACION Y CUL" },
      { value: "0085", label: "COOP DE A Y C DEL COL. FISC. VICENTE ROCAFUERTE" },
      { value: "0086", label: "COOP DE A Y C DESARROLLO POPULAR" },
      { value: "0087", label: "COOP DE A Y C EDUCACIONES TULCAN LTDA" },
      { value: "0088", label: "COOP DE A Y C EDUCADORES DE PASTAZA LTDA" },
      { value: "0089", label: "COOP DE A Y C EDUCADORES TULCAN LTDA" },
      { value: "0090", label: "COOP DE A Y C ESCENCIA INDIGENA LTDA" },
      { value: "0091", label: "COOP DE A Y C FENIX" },
      { value: "0092", label: "COOP DE A Y C FINANCIERA INDIGENA LTDA" },
      { value: "0093", label: "COOP DE A Y C FUTURO Y PROGRESO DE GALAPAGOS LTDA" },
      { value: "0094", label: "COOP DE A Y C GENERAL RUMINAHUI" },
      { value: "0095", label: "COOP DE A Y C GONZANAMA MIES" },
      { value: "0096", label: "COOP DE A Y C INKA KIPU LTDA" },
      { value: "0097", label: "COOP DE A Y C JADAN LTDA (MIES)" },
      { value: "0098", label: "COOP DE A Y C LUCHA CAMPESINA LTDA" },
      { value: "0099", label: "COOP DE A Y C MAQUITA CUSHUN LTDA" },
      { value: "0100", label: "COOP DE A Y C MAQUITA CUSHUNCHIC LTDA" },
      { value: "0101", label: "COOP DE A Y C MUSHUK YUYAY" },
      { value: "0102", label: "COOP DE A Y C PIJAL" },
      { value: "0103", label: "COOP DE A Y C SAN SANTIAGO DE MOLLETURO LTDA" },
      { value: "0104", label: "COOP DE A Y C SANTA ROSA DE PAUTAN LTDA" },
      { value: "0105", label: "COOP DE A Y C SIERRA CENTRO LTDA" },
      { value: "0106", label: "COOP DE A Y C SINCHI RUNA LTDA" },
      { value: "0107", label: "COOP DE A Y C SUMAC LLACTA LTDA" },
      { value: "0108", label: "COOP DE A Y C UNION MERCEDARIA LTDA" },
      { value: "0109", label: "COOP DE A Y C VALLES DEL LIRIO" },
      { value: "0110", label: "COOP DE A Y C VENCEDORES DE TUNGURAHUA" },
      { value: "0111", label: "COOP DE A Y C WUAMANLOMA" },
      { value: "0112", label: "COOP DE AHORRO Y CREDITO 22 DE JUNIO" },
      { value: "0113", label: "COOP DE AHORRO Y CREDITO 23 DE ENERO" },
      { value: "0114", label: "COOP DE AHORRO Y CREDITO 27 DE ABRIL LOJA" },
      { value: "0115", label: "COOP DE AHORRO Y CREDITO 29 DE ENERO" },
      { value: "0116", label: "COOP DE AHORRO Y CREDITO AGRICOLA JUNIN LTDA" },
      { value: "0117", label: "COOP DE AHORRO Y CREDITO AMBATO LTDA" },
      { value: "0118", label: "COOP DE AHORRO Y CREDITO ARTESANOS LTDA" },
      { value: "0119", label: "COOP DE AHORRO Y CREDITO CAC-CICA MIES" },
      { value: "0120", label: "COOP DE AHORRO Y CREDITO CACPE CELICA" },
      { value: "0121", label: "COOP DE AHORRO Y CREDITO CUMBENITA LTDA" },
      { value: "0122", label: "COOP DE AHORRO Y CREDITO DORADO LTDA" },
      { value: "0123", label: "COOP DE AHORRO Y CREDITO ERCO LTDA" },
      { value: "0124", label: "COOP DE AHORRO Y CREDITO FASAYNAN LTDA" },
      { value: "0125", label: "COOP DE AHORRO Y CREDITO FERNANDO DAQUILEMA" },
      { value: "0126", label: "COOP DE AHORRO Y CREDITO FORTUNA MIES" },
      { value: "0127", label: "COOP DE AHORRO Y CREDITO GUACHAPALA LTDA" },
      { value: "0128", label: "COOP DE AHORRO Y CREDITO GUEL LTDA" },
      { value: "0129", label: "COOP DE AHORRO Y CREDITO HUAICANA LTDA" },
      { value: "0130", label: "COOP DE AHORRO Y CREDITO HUAQUILLAS LTDA" },
      { value: "0131", label: "COOP DE AHORRO Y CREDITO HUAYCO PUNGO LTDA" },
      { value: "0132", label: "COOP DE AHORRO Y CREDITO INTEGRAL" },
      { value: "0133", label: "COOP DE AHORRO Y CREDITO LA FLORIDA" },
      { value: "0134", label: "COOP DE AHORRO Y CREDITO LA MERCED" },
      { value: "0135", label: "COOP DE AHORRO Y CREDITO MARCABELI LTDA" },
      { value: "0136", label: "COOP DE AHORRO Y CREDITO MIGRANTE SOLIDARIO" },
      { value: "0137", label: "COOP DE AHORRO Y CREDITO NUESTROS ABUELOS LTDA" },
      { value: "0138", label: "COOP DE AHORRO Y CREDITO NUEVA ESPERANZA" },
      { value: "0139", label: "COOP DE AHORRO Y CREDITO NUEVA HUANCAVILCA" },
      { value: "0140", label: "COOP DE AHORRO Y CREDITO PILAHUIN TIO LTDA" },
      { value: "0141", label: "COOP DE AHORRO Y CREDITO POPULAR Y SOLIDARIA" },
      { value: "0142", label: "COOP DE AHORRO Y CREDITO PUERTO LOPEZ LTDA" },
      { value: "0143", label: "COOP DE AHORRO Y CREDITO QUILANGA LTDA" },
      { value: "0144", label: "COOP DE AHORRO Y CREDITO SAN JORGE LTDA" },
      { value: "0145", label: "COOP DE AHORRO Y CREDITO SAN JOSE EL AIRO" },
      { value: "0146", label: "COOP DE AHORRO Y CREDITO SAN MIGUEL DE SIGCHOS" },
      { value: "0147", label: "COOP DE AHORRO Y CREDITO SAN PEDRO DE TABOADA" },
      { value: "0148", label: "COOP DE AHORRO Y CREDITO SANTA ANITA LTDA" },
      { value: "0149", label: "COOP DE AHORRO Y CREDITO SIMIATUG LTDA" },
      { value: "0150", label: "COOP DE LA PEQ Y MEDIANA EMPR CIUDADANA DE MACARA" },
      { value: "0151", label: "COOP DE SERV MULTIPLES AGRO VIDA" },
      { value: "0152", label: "COOP ESFUERZO UNIDO PARA EL DESARR DEL CHILCO" },
      { value: "0153", label: "COOP MANUEL ESTEBAN GODOY ORTEGA LTDA COOPMEGO" },
      { value: "0154", label: "COOP OLMEDO" },
      { value: "0155", label: "COOP SOLIDARIA DE GUALAQUIZA" },
      { value: "0156", label: "COOP SOLIDARIDAD Y PROGRESO ORIENTAL" },
      { value: "0157", label: "COOP. 15 DE DICIEMBRE" },
      { value: "0158", label: "COOP. A. Y C. CAMARA DE COMERCIO DE PINDAL CADECOP" },
      { value: "0159", label: "COOP. A. Y C. DE LA PEQ. EMP. CACPE ZAMORA LTDA" },
      { value: "0160", label: "COOP. AGUILAS DE CRISTO" },
      { value: "0161", label: "COOP. AHORRO Y CREDITO 15 DE ABRIL LTDA" },
      { value: "0162", label: "COOP. AHORRO Y CREDITO 23 DE JULIO" },
      { value: "0163", label: "COOP. AHORRO Y CREDITO 29 DE OCTUBRE" },
      { value: "0164", label: "COOP. AHORRO Y CREDITO 9 DE OCTUBRE LTDA" },
      { value: "0165", label: "COOP. AHORRO Y CREDITO ACCION RURAL" },
      { value: "0166", label: "COOP. AHORRO Y CREDITO ALIANZA MINAS LTDA." },
      { value: "0167", label: "COOP. AHORRO Y CREDITO AMAZONAS LTDA." },
      { value: "0168", label: "COOP. AHORRO Y CREDITO ANDALUCIA" },
      { value: "0169", label: "COOP. AHORRO Y CREDITO COTOCOLLAO" },
      { value: "0170", label: "COOP. AHORRO Y CREDITO D LA PEQ EMPR CACPE BIBLIAN" },
      { value: "0171", label: "COOP. AHORRO Y CREDITO DE LA PEQUENA EMPRESA GUALA" },
      { value: "0172", label: "COOP. AHORRO Y CREDITO DESARROLLO PUEBLOS" },
      { value: "0173", label: "COOP. AHORRO Y CREDITO EL SAGRARIO" },
      { value: "0174", label: "COOP. AHORRO Y CREDITO FAMILIA AUSTRAL" },
      { value: "0175", label: "COOP. AHORRO Y CREDITO GUARANDA LTDA" },
      { value: "0176", label: "COOP. AHORRO Y CREDITO JUAN DE SALINAS LTDA." },
      { value: "0177", label: "COOP. AHORRO Y CREDITO JUVENTUD" },
      { value: "0178", label: "COOP. AHORRO Y CREDITO LOS RIOS" },
      { value: "0179", label: "COOP. AHORRO Y CREDITO MALCHINGUI LTDA." },
      { value: "0180", label: "COOP. AHORRO Y CREDITO MANANTIAL DE ORO LTDA." },
      { value: "0181", label: "COOP. AHORRO Y CREDITO MANUEL" },
      { value: "0182", label: "COOP. AHORRO Y CREDITO NUEVA JERUSALEN" },
      { value: "0183", label: "COOP. AHORRO Y CREDITO OSCUS" },
      { value: "0184", label: "COOP. AHORRO Y CREDITO PABLO MUNOZ VEGA" },
      { value: "0185", label: "COOP. AHORRO Y CREDITO PROGRESO" },
      { value: "0186", label: "COOP. AHORRO Y CREDITO PUELLARO LTDA" },
      { value: "0187", label: "COOP. AHORRO Y CREDITO RIOBAMBA" },
      { value: "0188", label: "COOP. AHORRO Y CREDITO SAN FRANCISCO" },
      { value: "0189", label: "COOP. AHORRO Y CREDITO SAN GABRIEL LTDA." },
      { value: "0190", label: "COOP. AHORRO Y CREDITO SAN JOSE LTDA" },
      { value: "0191", label: "COOP. AHORRO Y CREDITO SAN MIGUEL DE LOS BANCOS" },
      { value: "0192", label: "COOP. AHORRO Y CREDITO SEMILLA DEL PROGRESO LTDA" },
      { value: "0193", label: "COOP. AHORRO Y CREDITO SENOR DE GIRON" },
      { value: "0194", label: "COOP. AHORRO Y CREDITO TENA LTDA." },
      { value: "0195", label: "COOP. AHORRO Y CREDITO TULCAN" },
      { value: "0196", label: "COOP. AHORRO. Y CREDI. MUJERES UNIDAS TANTANAKUSHK" },
      { value: "0197", label: "COOP. CALCETA LTDA" },
      { value: "0198", label: "COOP. CREDITO Y AHORRO SAN FRANCISCO DE ASIS" },
      { value: "0199", label: "COOP. DE A Y C. SERVIDORES MUNICIPALES DE CUENCA" },
      { value: "0200", label: "COOP. DE A. Y C. 13 DE ABRIL LTDA" },
      { value: "0201", label: "COOP. DE A. Y C. ALLI TARPUC LTDA." },
      { value: "0202", label: "COOP. DE A. Y C. CHIBULEO LTDA." },
      { value: "0203", label: "COOP. DE A. Y C. COOPINDIGENA LTDA." },
      { value: "0204", label: "COOP. DE A. Y C. DESARROLLO INTEGRAL LTDA" },
      { value: "0205", label: "COOP. DE A. Y C. EL TESORO PILLARENO" },
      { value: "0206", label: "COOP. DE A. Y C. EL TRANSPORTISTA CACET" },
      { value: "0207", label: "COOP. DE A. Y C. GRAMEEN AMAZONAS" },
      { value: "0208", label: "COOP. DE A. Y C. ILINIZA LTDA." },
      { value: "0209", label: "COOP. DE A. Y C. INDIGENA ALFA Y OMEGA LTDA" },
      { value: "0210", label: "COOP. DE A. Y C. JUAN PIO DE MORA LTDA" },
      { value: "0211", label: "COOP. DE A. Y C. JUVENTUD UNIDA LTDA." },
      { value: "0212", label: "COOP. DE A. Y C. KISAPINCHA LTDA." },
      { value: "0213", label: "COOP. DE A. Y C. LA UNION LTDA." },
      { value: "0214", label: "COOP. DE A. Y C. LOS CHASQUIS PASTOCALLE LTDA." },
      { value: "0215", label: "COOP. DE A. Y C. MUSHUG CAUSAY LTDA." },
      { value: "0216", label: "COOP. DE A. Y C. PADRE VICENTE PONCE RUBIO" },
      { value: "0217", label: "COOP. DE A. Y C. SALASACA" },
      { value: "0218", label: "COOP. DE A. Y C. SALINAS LTDA." },
      { value: "0219", label: "COOP. DE A. Y C. SAN PEDRO LTDA." },
      { value: "0220", label: "COOP. DE A. Y C. SUMAK SAMY LTDA." },
      { value: "0221", label: "COOP. DE A. Y C. UNION QUISAPINCHA LTDA." },
      { value: "0222", label: "COOP. DE A. Y C. VIRGEN DEL CISNE" },
      { value: "0223", label: "COOP. DE AHO Y CRED LOS ANDES LATINOS LTDA." },
      { value: "0224", label: "COOP. DE AHORRO Y CRED. SANTA ROSA LTDA" },
      { value: "0225", label: "COOP. DE AHORRO Y CREDITO 4 DE OCTUBRE LTDA." },
      { value: "0226", label: "COOP. DE AHORRO Y CREDITO ACCION Y DESARROLLO LTDA" },
      { value: "0227", label: "COOP. DE AHORRO Y CREDITO ANDINA LTDA." },
      { value: "0228", label: "COOP. DE AHORRO Y CREDITO ATUNTAQUI LTDA." },
      { value: "0229", label: "COOP. DE AHORRO Y CREDITO COMERCIO LTDA PORTOVIEJO" },
      { value: "0230", label: "COOP. DE AHORRO Y CREDITO COOPERA LTDA." },
      { value: "0231", label: "COOP. DE AHORRO Y CREDITO CRISTO REY" },
      { value: "0232", label: "COOP. DE AHORRO Y CREDITO EDUCADORES DE CHIMBORAZO" },
      { value: "0233", label: "COOP. DE AHORRO Y CREDITO EL CALVARIO LTDA" },
      { value: "0234", label: "COOP. DE AHORRO Y CREDITO GUARUMAL DEL CENTRO LTDA" },
      { value: "0235", label: "COOP. DE AHORRO Y CREDITO LA DOLOROSA LTDA" },
      { value: "0236", label: "COOP. DE AHORRO Y CREDITO ONCE DE JUNIO" },
      { value: "0237", label: "COOP. DE AHORRO Y CREDITO PEDRO MONCAYO LTDA." },
      { value: "0238", label: "COOP. DE AHORRO Y CREDITO PILAHUIN" },
      { value: "0239", label: "COOP. DE AHORRO Y CREDITO PROFUTURO LTDA." },
      { value: "0240", label: "COOP. DE AHORRO Y CREDITO PUCARA LTDA" },
      { value: "0241", label: "COOP. DE AHORRO Y CREDITO SARAGUROS" },
      { value: "0242", label: "COOP. DE LA MICROEMP. DE CHIMBORAZO" },
      { value: "0243", label: "COOP. JARDIN AZUAYO" },
      { value: "0244", label: "COOP. PREVISION AHORRO Y DESARROLLO" },
      { value: "0245", label: "COOP. PROD. Y DES. AGR. COOPRODESA LTDA" },
      { value: "0246", label: "COOP. SAN PABLO" },
      { value: "0247", label: "COOP.AHO Y CREDITO DE LA PEQ. EMP. DE LOJA CACPE" },
      { value: "0248", label: "COOP.AHORRO Y CREDITO ALIANZA DEL VALLE LTDA" },
      { value: "0249", label: "COOP.AHORRO Y CREDITO CHONE LTDA" },
      { value: "0250", label: "COOP.AHORRO Y CREDITO PRIMERO DE ENERO DEL AUSTRO" },
      { value: "0251", label: "COOP.DE AHORRO Y CREDITO POLICIA NACIONAL" },
      { value: "0252", label: "COOPERATIVA 15 DE AGOSTO PILACOTO" },
      { value: "0253", label: "COOPERATIVA DE AH. Y CREDITO RIOCHICO" },
      { value: "0254", label: "COOPERATIVA DE AHORRO Y CREDITO ALFONSO JARAMILLO" },
      { value: "0255", label: "COOPERATIVA DE AHORRO Y CREDITO CASA FACIL LTDA." },
      { value: "0256", label: "COOPERATIVA DE AHORRO Y CREDITO CREA LTDA ( MIES)" },
      { value: "0257", label: "COOPERATIVA DE AHORRO Y CREDITO INTI WASI LTDA." },
      { value: "0258", label: "COOPERATIVA DE AHORRO Y CREDITO LLANGANATES" },
      { value: "0259", label: "COOPERATIVA DE AHORRO Y CREDITO MINGA LTDA." },
      { value: "0260", label: "COOPERATIVA DE AHORRO Y CREDITO PROVIDA" },
      { value: "0261", label: "COOPERATIVA DE AHORRO Y CREDITO SAN ISIDRO LTDA." },
      { value: "0262", label: "COOPERATIVA DE AHORRO Y CREDITO SAN JOSE S.J." },
      { value: "0263", label: "COOPERATIVA DE AHORRO Y CREDITO SANTA ANA LTDA" },
      { value: "0264", label: "CORP. DE DES. SOCIAL Y FINANC ISLAS ENCANTADAS" },
      { value: "0265", label: "CORPORACION DE DESARROLLO FINANCIERA RHUMY WARA" },
      { value: "0266", label: "CORPORACION EN LAS HUELLAS DEL BCO GRAMEEN" },
      { value: "0267", label: "CORPORACION VIENTOS SOLIDARIOS" },
      { value: "0268", label: "DE AHORRO Y CREDITO CRECIENDO JUNTOS" },
      { value: "0269", label: "DE AHORRO Y CREDITO FCO. DE ORELLANA" },
      { value: "0270", label: "FINANCIERA - DINERS CLUB DEL ECUADOR" },
      { value: "0271", label: "FINANCIERA CONSULCREDITOS SA" },
      { value: "0272", label: "FINANCOOP" },
      { value: "0273", label: "FONDO DE CESANTIA DEL MAGISTERIO ECUAT FCME-FCPC" },
      { value: "0274", label: "INTERDIN S.A." },
      { value: "0275", label: "MUTUALISTA AMBATO" },
      { value: "0276", label: "MUTUALISTA AZUAY" },
      { value: "0277", label: "MUTUALISTA IMBABURA" },
      { value: "0278", label: "MUTUALISTA PICHINCHA" },
      { value: "0279", label: "VAZCORP SOCIEDAD FINANCIERA S.A." },
    ],
    PE: [
      { value: "", label: __("Select Bank") },
      { value: "0001", label: "BANCO INTERNACIONAL DEL PERU (INTERBANK)", accountNumberType: "CB" },
      { value: "0002", label: "BANCO BBVA PERU", accountNumberType: "CCI" },
      { value: "0003", label: "BANCO BCP PERU", accountNumberType: "CB" },
      { value: "0004", label: "BANCO SCOTIABANK PERU", accountNumberType: "CB" },
      { value: "0005", label: "BANCO INTERAMERICANO DE FINANZAS", accountNumberType: "CB" },
      { value: "0006", label: "PICHINCHA", accountNumberType: "CB" },
      { value: "0007", label: "MIBANCO", accountNumberType: "CCI" },
      { value: "0008", label: "BANCO DE LA NACION", accountNumberType: "CCI" },
      { value: "0009", label: "BANCO DE COMERCIO", accountNumberType: "CB" },
      { value: "0010", label: "BANCO SANTANDER", accountNumberType: "CCI" },
      { value: "0011", label: "CITIBANK DEL PERU", accountNumberType: "CCI" },
    ],
  };

  const pRAccountTypes = {
    BR: [
      { value: "", label: __("Select Account Type") },
      { value: "0001", label: "Poupança" },
      { value: "0002", label: "Conta Corrente" },
    ],
    CL: [
      { value: "", label: __("Select Account Type") },
      { value: "0001", label: "Cuenta Corriente / Cuenta Vista" },
      { value: "0003", label: "Chequera electrónica" },
      { value: "0004", label: "Cuenta RUT" },
    ],
    MX: [
      { value: "", label: __("Select Account Type") },
      { value: "0001", label: "General" },
    ],
    CO: [
      { value: "", label: __("Select Account Type") },
      { value: "0001", label: "Ahorros" },
      { value: "0002", label: "Corriente" },
    ],
    EC: [
      { value: "", label: __("Select Account Type") },
      { value: "0001", label: "Savings Bank" },
      { value: "0002", label: "Checking Account" },
    ],
    PE: [
      { value: "", label: __("Select Account Type") },
      { value: "0001", label: "Savings Bank" },
      { value: "0002", label: "Checking Account" },
    ],
  };

  const prIdDocTypes = {
    BR: [
      { value: "", label: __("Select ID Document") },
      { value: "1", label: "CPF" },
    ],
    CL: [
      { value: "", label: __("Select ID Document") },
      { value: "cl_rut", label: "RUT" },
    ],
    MX: [
      { value: "", label: __("Select ID Document") },
      { value: "40", label: "General" },
    ],
    CO: [
      { value: "", label: __("Select ID Document") },
      { value: "01", label: "Cedula Ciudadanía (CC)" },
      { value: "02", label: "Cedular de Extranjería (CE)" },
      { value: "03", label: "NIT (NT)" },
      { value: "04", label: "Tarjeta de Identidad (TI)" },
      { value: "06", label: "NII de Establecimientos (NE)" },
    ],
    EC: [
      { value: "", label: __("Select ID Document") },
      { value: "04", label: "DNI/Identification Card" },
    ],
    PE: [
      { value: "", label: __("Select ID Document") },
      { value: "04", label: "DNI/Identification Card" },
    ],
  };
  const prBeneficiaryTypes = {
    CL: [
      { value: "", label: __("Select Beneficiary Type") },
      { value: "person", label: __("Person") },
      { value: "company", label: __("Company") },
    ],
  };

  const [payRetailerAdditionalValues, setPayRetailerAdditionalValues] = useState({});

  const updatePayRetailerAdditionalValues = (value, field) => {
    setPayRetailerAdditionalValues({ ...payRetailerAdditionalValues, [field]: value });
  };

  useEffect(() => {
    if (withdrawalMethod && withdrawalMethod.indexOf("PayRetailer:")) {
      if (withdrawalMethod === "PayRetailer:BankTransfer") {
      } else if (withdrawalMethod === "PayRetailer:Cash") {
        var additionalDetails = [{}];
      }
    }
  }, [withdrawalMethod, country]);

  useEffect(() => {
    getWithdrawalMethods();
  }, [withdrawalOption]);
  useEffect(() => {
    setAmountError("");
  }, [withdrawalAmount]);
  // }, [withdrawalAmount, withdrawalOption]);

  const prCountries = ["BR", "EC", "CL", "CO", "MX", "PE"];

  return (
    <>
      <div className="dashboard_content">
        <UserLeftArea section="Withdraw" {...props} />
        <div className="right_area white">
          <p className="text">{__("Use the form below to withdraw funds from your account.")}</p>
          {__("Amount that can be withdrawn:")} <b>{withdrawalDetails.total_withdrawal_amount}</b> USD
          <p className="text">
            {__("Total locked bonus amount:")} <b>{withdrawalDetails.locked_bonus_amount}</b> USD
            <br />
            {__("Total bet turnover:")} <b>{withdrawalDetails.bet_turnover}</b> USD
            <br />
            {__("Total bet amount necessary to unlock remaining bonus:")} <b>{withdrawalDetails.amount_2_unlock}</b> USD
            &nbsp;
            <br />
            &nbsp;
          </p>
          <p>
            {__("Choose payment system")}
            <br />
            &nbsp;
            <i>{__("*you can make a withdrawal only by using payment system you made deposit before")}</i>
            <br />
            &nbsp;
          </p>
          <ul className="withdrawalOptionMenu">
            <li>
              <input
                id="cryptoOption"
                type="radio"
                name="withdrawalOption"
                checked={withdrawalOption === "E-Wallets"}
                onChange={(ev) => {
                  setWithdrawalError("");
                  setWithdrawalOption("E-Wallets");
                }}
              />
              <label for="cryptoOption">{__("Online Methods")}</label>
            </li>
            <li>
              <input
                id="cryptoOption"
                type="radio"
                name="withdrawalOption"
                checked={withdrawalOption === "Crypto"}
                onChange={(ev) => setWithdrawalOption("Crypto")}
              />
              <label for="cryptoOption">{__("Crypto")}</label>
            </li>
            <li>
              <input
                id="wireTransferOption"
                type="radio"
                name="withdrawalOption"
                checked={withdrawalOption === "Wire Transfer"}
                onChange={(ev) => {
                  setWithdrawalError("");
                  setWithdrawalOption("Wire Transfer");
                }}
              />
              <label for="wireTransferOption">{__("Wire Transfer")}</label>
            </li>
            <li>
              <input
                id="creditCardsOption"
                type="radio"
                name="withdrawalOption"
                checked={withdrawalOption === "Credit Cards"}
                onChange={(ev) => {
                  setWithdrawalError("");
                  setWithdrawalOption("Credit Cards");
                }}
              />
              <label for="creditCardsOption">{__("Credit Cards")}</label>
            </li>
            <li>
              <input
                id="cashOption"
                type="radio"
                name="withdrawalOption"
                checked={withdrawalOption === "Cash Deposit"}
                onChange={(ev) => {
                  setWithdrawalError("");
                  setWithdrawalOption("Cash Deposit");
                }}
              />
              <label for="cashOption">{__("Cash")}</label>
            </li>
            <li>
              <input
                id="paypalOption"
                type="radio"
                name="withdrawalOption"
                checked={withdrawalOption === "Paypal"}
                onChange={(ev) => {
                  setWithdrawalError("");
                  setWithdrawalOption("Paypal");
                }}
              />
              <label for="paypalOption">{__("Paypal")}</label>
            </li>
            <li>
              <input
                id="othersOption"
                type="radio"
                name="withdrawalOption"
                checked={withdrawalOption === "Other"}
                onChange={(ev) => {
                  setWithdrawalError("");
                  setWithdrawalOption("Other");
                }}
              />
              <label for="othersOption">{__("Other")}</label>
            </li>
          </ul>
          {withdrawalOption === "E-Wallets" && (
            <>
              <p>{__("Select Method")}</p>
              <form method="post" id="withdrawForm" onSubmit={showWithdrwalConfirmation}>
                <div className="row">
                  <div className="col-12 deposit-methods">
                    <div className="payment_method_list">
                      <div className="banking_area">
                        <div className="banking_list_area">
                          {withdrawalMethods.map((method, index) => (
                            <div className="single_banking e_wallet_method">
                              <input
                                id={"all_" + method.id}
                                onChange={() => setWithdrawalMethod(method.method_id)}
                                type="radio"
                                checked={method.method_id === withdrawalMethod}
                              />
                              <label htmlFor={"all_" + method.id}>
                                <ul>
                                  <li className="payment_logo_area">
                                    <span className="helper"></span>
                                    <span className="payment_text_mobile">
                                      <span className="helper"></span>
                                      <span className="method_logo_text">{__("Method Logo")}</span>
                                    </span>
                                    <div
                                      className="method_logo"
                                      style={{
                                        backgroundImage: "url(" + method.logo + ")",
                                      }}
                                    ></div>
                                    <div className="clearfix"></div>
                                  </li>
                                  <li className="payment_method_name">
                                    <span className="helper"></span>
                                    <span className="payment_text_mobile">{__("Method Name")}</span>
                                    <span className="payment_content"> {method.method}</span>
                                    <div className="clearfix"></div>
                                  </li>
                                  <li className="min_payment_amount">
                                    <span className="payment_text_mobile">{__("Limit Amount")}</span>
                                    <span className="payment_content">
                                      {__("MIN")} {method.min_withdrawal} USD
                                      <br />
                                      <i>
                                        **{method.withdrawal_fee}%{" "}
                                        {__("Transaction fee will be deducted from your withdrawal amount.")}
                                      </i>
                                    </span>
                                    <div className="clearfix"></div>
                                  </li>
                                </ul>
                              </label>
                              <label htmlFor={"all_" + method.id} className="payment_check_mark">
                                <i className="fas fa-check selected_method_icon"></i>
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <input
                      type="number"
                      value={withdrawalAmount}
                      onChange={(ev) => setWithdrawalAmount(ev.target.value)}
                      className="form-control"
                      placeholder={__("Amount")}
                    />
                    {amountError && <div className="error">{amountError}</div>}
                    <input type="hidden" id="verifiedCode" className="form-control" name="verified_code" />
                    <br />
                  </div>
                  <div className="button_group">
                    <Link onClick={showWithdrwalConfirmation} className="common_btn uppercase no_margin">
                      {__("Submit withdrawal")}
                    </Link>
                  </div>
                </div>
              </form>
            </>
          )}
          {withdrawalOption === "Crypto" && (
            <>
              <p>{__("Choose Coin")}</p>
              <form method="post" id="withdrawForm" onSubmit={showWithdrwalConfirmation}>
                <div className="row">
                  {!isWalletAddressRequired(withdrawalMethod) && (
                    <div className="col-md-12 account_number_explanation">
                      <br />
                      <div className="alert alert-warning">
                        <b>{__("Withdrawals will be sent to the same account used for deposit with this method.")}</b>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-12 deposit-methods">
                    <div className="payment_method_list">
                      <div className="banking_area">
                        <div className="banking_list_area">
                          {/* <div className="small_info">
                        {__(
                          "Click on the 'i' icon within a logo for more information about a payment method."
                        )}
                      </div> */}

                          {withdrawalMethods.map((method, index) => (
                            <div className="single_banking e_wallet_method">
                              <input
                                id={"all_" + method.id}
                                onChange={() => setWithdrawalMethod(method.method_id)}
                                type="radio"
                                checked={method.method_id === withdrawalMethod}
                              />
                              <label htmlFor={"all_" + method.id}>
                                <ul>
                                  <li className="payment_logo_area">
                                    {/* <a
                                  target="_blank"
                                  href="https://www.counos.io/CounosX"
                                  className="icon_popover"
                                >
                                  i
                                </a> */}
                                    <span className="helper"></span>
                                    <span className="payment_text_mobile">
                                      <span className="helper"></span>
                                      <span className="method_logo_text">{__("Method Logo")}</span>
                                    </span>
                                    <div
                                      className="method_logo"
                                      style={{
                                        backgroundImage: "url(" + method.logo + ")",
                                      }}
                                    ></div>
                                    <div className="clearfix"></div>
                                  </li>
                                  <li className="payment_method_name">
                                    <span className="helper"></span>
                                    <span className="payment_text_mobile">{__("Method Name")}</span>
                                    <span className="payment_content"> {method.method}</span>
                                    <div className="clearfix"></div>
                                  </li>
                                  <li className="min_payment_amount">
                                    <span className="payment_text_mobile">{__("Limit Amount")}</span>
                                    <span className="payment_content">
                                      {__("MIN")} {method.min_withdrawal} USD
                                      <br />
                                      <i>
                                        **{method.withdrawal_fee}%{" "}
                                        {__("Transaction fee will be deducted from your withdrawal amount.")}
                                      </i>
                                    </span>
                                    <div className="clearfix"></div>
                                  </li>
                                </ul>
                              </label>
                              <label htmlFor={"all_" + method.id} className="payment_check_mark">
                                <i className="fas fa-check selected_method_icon"></i>
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <input
                      type="number"
                      value={withdrawalAmount}
                      onChange={(ev) => setWithdrawalAmount(ev.target.value)}
                      className="form-control"
                      placeholder={__("Amount")}
                    />
                    {amountError && <div className="error">{amountError}</div>}
                    <input type="hidden" id="verifiedCode" className="form-control" name="verified_code" />
                    <br />
                  </div>
                  {isWalletAddressRequired(withdrawalMethod) && (
                    <div className="col-sm-12 account_number">
                      <input
                        required
                        type="text"
                        value={withdrawalWallet.toString()}
                        onChange={(ev) => setWithdrawalWallet(ev.target.value)}
                        className="form-control acc_number"
                        placeholder={__("Wallet Address")}
                      />
                      <br />
                    </div>
                  )}

                  <div className="col-sm-12 account_number">
                    <input
                      readOnly={true}
                      type="email"
                      value={userDetails.email}
                      className="form-control email_address"
                      placeholder={__("User Email")}
                    />
                    <br />
                  </div>
                  <div className="button_group">
                    <Link onClick={showWithdrwalConfirmation} className="common_btn uppercase no_margin">
                      {__("Submit withdrawal")}
                    </Link>
                  </div>
                </div>
              </form>
            </>
          )}
          {withdrawalOption === "Wire Transfer" && (
            <form method="post">
              <div className="row">
                <div className="col-12 deposit-methods">
                  <div className="payment_method_list">
                    <div className="banking_area">
                      <div className="banking_list_area">
                        {/* <div className="small_info">
                        {__(
                          "Click on the 'i' icon within a logo for more information about a payment method."
                        )}
                      </div> */}

                        {withdrawalMethods.map((method, index) => (
                          <div className="single_banking e_wallet_method">
                            <input
                              id={"all_" + method.id}
                              onChange={() => {
                                setWithdrawalMethod(method.method_id);
                              }}
                              type="radio"
                              checked={method.method_id === withdrawalMethod}
                            />
                            <label htmlFor={"all_" + method.id}>
                              <ul>
                                <li className="payment_logo_area">
                                  {/* <a
                                  target="_blank"
                                  href="https://www.counos.io/CounosX"
                                  className="icon_popover"
                                >
                                  i
                                </a> */}
                                  <span className="helper"></span>
                                  <span className="payment_text_mobile">
                                    <span className="helper"></span>
                                    <span className="method_logo_text">{__("Method Logo")}</span>
                                  </span>
                                  <div
                                    className="method_logo"
                                    style={{
                                      backgroundImage: "url(" + method.logo + ")",
                                    }}
                                  ></div>
                                  <div className="clearfix"></div>
                                </li>
                                <li className="payment_method_name">
                                  <span className="helper"></span>
                                  <span className="payment_text_mobile">{__("Method Name")}</span>
                                  <span className="payment_content"> {method.method}</span>
                                  <div className="clearfix"></div>
                                </li>
                                <li className="min_payment_amount">
                                  <span className="payment_text_mobile">{__("Limit Amount")}</span>
                                  <span className="payment_content">
                                    {__("MIN")} {method.min_withdrawal} USD
                                    <br />
                                    <i>
                                      **{method.withdrawal_fee}%{" "}
                                      {__("Transaction fee will be deducted from your withdrawal amount.")}
                                    </i>
                                  </span>
                                  <div className="clearfix"></div>
                                </li>
                              </ul>
                            </label>
                            <label htmlFor={"all_" + method.id} className="payment_check_mark">
                              <i className="fas fa-check selected_method_icon"></i>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <input
                    type="number"
                    value={withdrawalAmount}
                    onChange={(ev) => setWithdrawalAmount(ev.target.value)}
                    className="form-control"
                    placeholder={__("Amount")}
                  />
                  {amountError && <div className="error">{amountError}</div>}
                  <br />
                </div>
                <div className="col-sm-12 account_number">
                  <input
                    readOnly={true}
                    type="email"
                    onChange={(ev) => setWithdrawalEmail(ev.target.value)}
                    value={userDetails.email}
                    className="form-control email_address"
                    placeholder={__("User Email")}
                  />
                  <br />
                </div>

                {withdrawalMethod === "PayRetailer:BankTransfer" && prCountries.indexOf(country) !== -1 && (
                  <>
                    <div className="col-sm-12">
                      <SelectBox
                        style={{ background: "white" }}
                        className="form-control input_box select_box"
                        items={prBanks[country]}
                        value={payRetailerAdditionalValues.bankName}
                        onChange={(itemValue) => updatePayRetailerAdditionalValues(itemValue, "bankName")}
                      />
                    </div>
                    <div className="col-sm-12">
                      <SelectBox
                        style={{ background: "white" }}
                        className="form-control input_box select_box"
                        items={pRAccountTypes[country]}
                        value={payRetailerAdditionalValues.accountType}
                        onChange={(itemValue) => updatePayRetailerAdditionalValues(itemValue, "accountType")}
                      />
                    </div>
                    <div className="col-sm-12 account_number">
                      <input
                        type="text"
                        value={payRetailerAdditionalValues.accountNumber}
                        onChange={(ev) => updatePayRetailerAdditionalValues(ev.target.value, "accountNumber")}
                        className="form-control account_number"
                        placeholder={__("Account Number")}
                      />
                      <br />
                    </div>

                    {country === "BR" && (
                      <>
                        <div className="col-sm-12">
                          <input
                            type="text"
                            value={payRetailerAdditionalValues.accountAgencyNumber}
                            onChange={(ev) => updatePayRetailerAdditionalValues(ev.target.value, "accountAgencyNumber")}
                            className="form-control"
                            placeholder={__("Account Agency Number")}
                          />
                          <br />
                        </div>

                        <div className="col-sm-12">
                          <input
                            type="text"
                            value={payRetailerAdditionalValues.abaSwift}
                            onChange={(ev) => updatePayRetailerAdditionalValues(ev.target.value, "abaSwift")}
                            className="form-control"
                            placeholder={__("ABA SWIFT")}
                          />
                          <br />
                        </div>
                      </>
                    )}
                    {country === "CL" && (
                      <>
                        <div className="col-sm-12">
                          <SelectBox
                            style={{ background: "white" }}
                            className="form-control input_box select_box"
                            items={prBeneficiaryTypes[country]}
                            value={payRetailerAdditionalValues.payoutBeneficiaryTypeCode}
                            onChange={(itemValue) =>
                              updatePayRetailerAdditionalValues(itemValue, "payoutBeneficiaryTypeCode")
                            }
                          />
                        </div>
                      </>
                    )}
                    <div className="col-sm-12">
                      <SelectBox
                        style={{ background: "white" }}
                        className="form-control input_box select_box"
                        items={prIdDocTypes[country]}
                        value={payRetailerAdditionalValues.idDocType}
                        onChange={(itemValue) => updatePayRetailerAdditionalValues(itemValue, "idDocType")}
                      />
                    </div>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        value={payRetailerAdditionalValues.idDocNumber}
                        onChange={(ev) => updatePayRetailerAdditionalValues(ev.target.value, "idDocNumber")}
                        className="form-control account_number"
                        placeholder={__("ID Document Number")}
                      />
                      <br />
                    </div>
                  </>
                )}
              </div>
              <div className="button_group">
                <Link onClick={showWithdrwalConfirmationDirect} className="common_btn uppercase no_margin">
                  {__("Submit withdrawal")}
                </Link>
              </div>
            </form>
          )}
          {withdrawalOption === "Cash Deposit" && (
            <form method="post">
              <div className="row">
                <div className="col-12 deposit-methods">
                  <div className="payment_method_list">
                    <div className="banking_area">
                      <div className="banking_list_area">
                        {/* <div className="small_info">
                        {__(
                          "Click on the 'i' icon within a logo for more information about a payment method."
                        )}
                      </div> */}

                        {withdrawalMethods.map((method, index) => (
                          <div className="single_banking e_wallet_method">
                            <input
                              id={"all_" + method.id}
                              onChange={() => {
                                setWithdrawalMethod(method.method_id);
                              }}
                              type="radio"
                              checked={method.method_id === withdrawalMethod}
                            />
                            <label htmlFor={"all_" + method.id}>
                              <ul>
                                <li className="payment_logo_area">
                                  {/* <a
                                  target="_blank"
                                  href="https://www.counos.io/CounosX"
                                  className="icon_popover"
                                >
                                  i
                                </a> */}
                                  <span className="helper"></span>
                                  <span className="payment_text_mobile">
                                    <span className="helper"></span>
                                    <span className="method_logo_text">{__("Method Logo")}</span>
                                  </span>
                                  <div
                                    className="method_logo"
                                    style={{
                                      backgroundImage: "url(" + method.logo + ")",
                                    }}
                                  ></div>
                                  <div className="clearfix"></div>
                                </li>
                                <li className="payment_method_name">
                                  <span className="helper"></span>
                                  <span className="payment_text_mobile">{__("Method Name")}</span>
                                  <span className="payment_content"> {method.method}</span>
                                  <div className="clearfix"></div>
                                </li>
                                <li className="min_payment_amount">
                                  <span className="payment_text_mobile">{__("Limit Amount")}</span>
                                  <span className="payment_content">
                                    {__("MIN")} {method.min_withdrawal} USD
                                    <br />
                                    <i>
                                      **{method.withdrawal_fee}%{" "}
                                      {__("Transaction fee will be deducted from your withdrawal amount.")}
                                    </i>
                                  </span>
                                  <div className="clearfix"></div>
                                </li>
                              </ul>
                            </label>
                            <label htmlFor={"all_" + method.id} className="payment_check_mark">
                              <i className="fas fa-check selected_method_icon"></i>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <input
                    type="number"
                    value={withdrawalAmount}
                    onChange={(ev) => setWithdrawalAmount(ev.target.value)}
                    className="form-control"
                    placeholder={__("Amount")}
                  />
                  {amountError && <div className="error">{amountError}</div>}
                  <br />
                </div>
                <div className="col-sm-12 account_number">
                  <input
                    readOnly={true}
                    type="email"
                    value={userDetails.email}
                    className="form-control email_address"
                    placeholder={__("User Email")}
                  />
                  <br />
                </div>
                {withdrawalMethod === "PayRetailer:Cash" && ["EC", "PE"].indexOf(country) !== -1 && (
                  <>
                    <div className="col-sm-12">
                      <SelectBox
                        style={{ background: "white" }}
                        className="form-control input_box select_box"
                        items={prIdDocTypes[country]}
                        value={payRetailerAdditionalValues.idDocType}
                        onChange={(itemValue) => updatePayRetailerAdditionalValues(itemValue, "idDocType")}
                      />
                    </div>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        value={payRetailerAdditionalValues.idDocNumber}
                        onChange={(ev) => updatePayRetailerAdditionalValues(ev.target.value, "idDocNumber")}
                        className="form-control account_number"
                        placeholder={__("ID Document Number")}
                      />
                      <br />
                    </div>
                  </>
                )}
              </div>
              <div className="button_group">
                <Link onClick={showWithdrwalConfirmationDirect} className="common_btn uppercase no_margin">
                  {__("Submit withdrawal")}
                </Link>
              </div>
            </form>
          )}
          {(withdrawalOption === "Credit Cards" || withdrawalOption === "Paypal") && (
            <form method="post">
              <div className="row">
                <div className="col-12 deposit-methods">
                  <div className="payment_method_list">
                    <div className="banking_area">
                      <div className="banking_list_area">
                        {/* <div className="small_info">
                        {__(
                          "Click on the 'i' icon within a logo for more information about a payment method."
                        )}
                      </div> */}

                        {withdrawalMethods.map((method, index) => (
                          <div className="single_banking e_wallet_method">
                            <input
                              id={"all_" + method.id}
                              onChange={() => {
                                setWithdrawalMethod(method.method_id);
                              }}
                              type="radio"
                              checked={method.method_id === withdrawalMethod}
                            />
                            <label htmlFor={"all_" + method.id}>
                              <ul>
                                <li className="payment_logo_area">
                                  {/* <a
                                  target="_blank"
                                  href="https://www.counos.io/CounosX"
                                  className="icon_popover"
                                >
                                  i
                                </a> */}
                                  <span className="helper"></span>
                                  <span className="payment_text_mobile">
                                    <span className="helper"></span>
                                    <span className="method_logo_text">{__("Method Logo")}</span>
                                  </span>
                                  <div
                                    className="method_logo"
                                    style={{
                                      backgroundImage: "url(" + method.logo + ")",
                                    }}
                                  ></div>
                                  <div className="clearfix"></div>
                                </li>
                                <li className="payment_method_name">
                                  <span className="helper"></span>
                                  <span className="payment_text_mobile">{__("Method Name")}</span>
                                  <span className="payment_content"> {method.method}</span>
                                  <div className="clearfix"></div>
                                </li>
                                <li className="min_payment_amount">
                                  <span className="payment_text_mobile">{__("Limit Amount")}</span>
                                  <span className="payment_content">
                                    {__("MIN")} {method.min_withdrawal} USD
                                    <br />
                                    <i>
                                      **{method.withdrawal_fee}%{" "}
                                      {__("Transaction fee will be deducted from your withdrawal amount.")}
                                    </i>
                                  </span>
                                  <div className="clearfix"></div>
                                </li>
                              </ul>
                            </label>
                            <label htmlFor={"all_" + method.id} className="payment_check_mark">
                              <i className="fas fa-check selected_method_icon"></i>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <input
                    type="number"
                    value={withdrawalAmount}
                    onChange={(ev) => setWithdrawalAmount(ev.target.value)}
                    className="form-control"
                    placeholder={__("Amount")}
                  />{" "}
                  {amountError && <div className="error">{amountError}</div>}
                  <br />
                </div>
                <div className="col-sm-12 account_number">
                  <input
                    readOnly={true}
                    type="email"
                    value={userDetails.email}
                    className="form-control email_address"
                    placeholder={__("User Email")}
                  />
                  <br />
                </div>
              </div>
              <div className="button_group">
                <Link onClick={showWithdrwalConfirmationDirect} className="common_btn uppercase no_margin">
                  {__("Submit withdrawal")}
                </Link>
              </div>
            </form>
          )}
          {withdrawalOption === "Other" && (
            <form method="post">
              <div className="row">
                <div className="col-12 deposit-methods">
                  <div className="payment_method_list">
                    <div className="banking_area">
                      <div className="banking_list_area">
                        {/* <div className="small_info">
                        {__(
                          "Click on the 'i' icon within a logo for more information about a payment method."
                        )}
                      </div> */}

                        {withdrawalMethods.map((method, index) => (
                          <div className="single_banking e_wallet_method">
                            <input
                              id={"all_" + method.id}
                              onChange={() => setWithdrawalMethod(method.method_id)}
                              type="radio"
                              checked={method.method_id === withdrawalMethod}
                            />
                            <label htmlFor={"all_" + method.id}>
                              <ul>
                                <li className="payment_logo_area">
                                  {/* <a
                                  target="_blank"
                                  href="https://www.counos.io/CounosX"
                                  className="icon_popover"
                                >
                                  i
                                </a> */}
                                  <span className="helper"></span>
                                  <span className="payment_text_mobile">
                                    <span className="helper"></span>
                                    <span className="method_logo_text">{__("Method Logo")}</span>
                                  </span>
                                  <div
                                    className="method_logo"
                                    style={{
                                      backgroundImage: "url(" + method.logo + ")",
                                    }}
                                  ></div>
                                  <div className="clearfix"></div>
                                </li>
                                <li className="payment_method_name">
                                  <span className="helper"></span>
                                  <span className="payment_text_mobile">{__("Method Name")}</span>
                                  <span className="payment_content"> {method.method}</span>
                                  <div className="clearfix"></div>
                                </li>
                                <li className="min_payment_amount">
                                  <span className="payment_text_mobile">{__("Limit Amount")}</span>
                                  <span className="payment_content">
                                    {__("MIN")} {method.min_withdrawal} USD
                                    <br />
                                    <i>
                                      **{method.withdrawal_fee}%{" "}
                                      {__("Transaction fee will be deducted from your withdrawal amount.")}
                                    </i>
                                  </span>
                                  <div className="clearfix"></div>
                                </li>
                              </ul>
                            </label>
                            <label htmlFor={"all_" + method.id} className="payment_check_mark">
                              <i className="fas fa-check selected_method_icon"></i>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <input
                    type="number"
                    value={withdrawalAmount}
                    onChange={(ev) => setWithdrawalAmount(ev.target.value)}
                    className="form-control"
                    placeholder={__("Amount")}
                  />
                  {amountError && <div className="error">{amountError}</div>}
                  <br />
                </div>
                <div className="col-sm-12">
                  <input
                    type="text"
                    value={paymentSystem}
                    onChange={(ev) => setPaymentSystem(ev.target.value)}
                    className="form-control"
                    placeholder={__(" Enter payment system")}
                  />
                  <br />
                </div>
                <div className="col-sm-12 account_number">
                  <input
                    readOnly={true}
                    type="email"
                    value={userDetails.email}
                    className="form-control email_address"
                    placeholder={__("User Email")}
                  />
                  <br />
                </div>
              </div>
              <div className="button_group">
                <Link onClick={showWithdrwalConfirmationDirect} className="common_btn uppercase no_margin">
                  {__("Submit withdrawal")}
                </Link>
              </div>
            </form>
          )}
        </div>
      </div>
      <Modal
        show={withdrawalConfirmationModal}
        onHide={closeWithdrwalConfirmation}
        id="popup-withdraw"
        className="modal cashierModal"
      >
        <Modal.Header>
          <h5 className="modal-title">{__("Withdrawal Confirmation")}</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeWithdrwalConfirmation}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {withdrawalError ? (
            <p>{withdrawalError}</p>
          ) : (
            <p>
              {__(
                "Are you sure you want to make a withdrawal? Any remaining Bonus Balance or Free Spins will be forfeited."
              )}{" "}
            </p>
          )}
          <div className="button_group">
            {!withdrawalError && (
              <button type="button" className="common_btn uppercase no_margin ConfirmBtn" onClick={submitWithdraw}>
                {__("confirm")}
              </button>
            )}
            <button type="button" className="common_btn uppercase no_margin" onClick={closeWithdrwalConfirmation}>
              {__("Close")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
