import React, { useState, useEffect, useRef } from "react";
import LoadMore from "elements/LoadMore";
import GameItem from "elements/games_elements/GameItem";
import { Link, useParams } from "react-router-dom";
import { Modal, ProgressBar } from "react-bootstrap";
import { __ } from "services/Translator";

export default function CategoryGames(props) {
  const params = useParams();
  const category = params.category;
  const providers = props.providers;
  const selectedProviders = props.selectedProviders;
  const setSelectedProviders = props.setSelectedProviders;

  const [providersModal, setProvidersModal] = useState(false);
  const closeAllModals = () => {
    setProvidersModal(false);
  };
  const toggleProviersModal = () => {
    setProvidersModal(!providersModal);
  };
  const toggleSelectedProvider = (providerId) => {
    var providersClone = JSON.parse(JSON.stringify(selectedProviders));
    if (providersClone.indexOf(providerId) === -1) {
      providersClone.push(providerId);
    } else {
      providersClone.splice(providersClone.indexOf(providerId), 1);
    }
    setSelectedProviders(providersClone);
  };

  const clearAllProviders = () => {
    setSelectedProviders([]);
  };

  return (
    <>
      <div className="category_games_header">
        <div className="headline_left">
          <h4 className=" common_headline_with_bg">{props.categoryName}</h4>
          {props.isMobileDevice ? (
            <></>
          ) : (
            <>
              {/* <Link
                to="#"
                onClick={() => {
                  props.setGameSorting("Most Popular");
                }}
                className={
                  "common_btn medium " +
                  (props.gameSorting === "Most Popular" ? "active" : "inactive")
                }
              >
                Popular
              </Link>
              <Link
                to="#"
                onClick={() => {
                  props.setGameSorting("Newest First");
                }}
                className={
                  "common_btn medium " +
                  (props.gameSorting === "Newest First" ? "active" : "inactive")
                }
              >
                New
              </Link>
              <Link
                to="#"
                onClick={() => {
                  props.setGameSorting("Most Played");
                }}
                className={
                  "common_btn medium " +
                  (props.gameSorting === "Most Played" ? "active" : "inactive")
                }
              >
                Most Played
              </Link> */}
            </>
          )}
        </div>

        {(props.category === "live-casino" ||
          props.category === "blackjack" ||
          props.category === "baccarat" ||
          props.category === "video-poker" ||
          props.category === "roulette") &&
          !props.isMobileDevice && (
            <div className="sub_game_menu">
              <ul>
                <li>
                  <Link className={category == "live-casino" ? "active" : ""} to="/games/live-casino">
                    {__("Live Casino")}
                  </Link>
                </li>
                <li>
                  <Link className={category == "blackjack" ? "active" : ""} to="/games/blackjack">
                    {__("Blackjack")}
                  </Link>
                </li>
                <li>
                  <Link className={category == "roulette" ? "active" : ""} to="/games/roulette">
                    {__("Roulette")}
                  </Link>
                </li>
                <li>
                  <Link className={category == "video-poker" ? "active" : ""} to="/games/video-poker">
                    {__("Poker")}
                  </Link>
                </li>
                <li>
                  <Link className={category == "baccarat" ? "active" : ""} to="/games/baccarat">
                    {__("Baccarat")}
                  </Link>
                </li>
                {/* <li>
                  <Link className={category == "other" ? "active" : ""} to="/games/other">
                    {__("Other")}
                  </Link>
                </li> */}
              </ul>
            </div>
          )}

        <div className="headline_right">
          {/* <SearchBox {...props} /> */}
          <Link
            onClick={toggleProviersModal}
            className={"common_btn  " + (props.selectedProviders.length ? "" : "borderd")}
          >
            {__("Providers")}
          </Link>
        </div>
      </div>

      {(props.category === "live-casino" ||
        props.category === "blackjack" ||
        props.category === "baccarat" ||
        props.category === "video-poker" ||
        props.category === "roulette") &&
        props.isMobileDevice && (
          <div className="sub_game_menu">
            <ul>
              <li>
                <Link className={category == "blackjack" ? "active" : ""} to="/games/blackjack">
                  {__("Blackjack")}
                </Link>
              </li>
              <li>
                <Link className={category == "roulette" ? "active" : ""} to="/games/roulette">
                  {__("Roulette")}
                </Link>
              </li>
              <li>
                <Link className={category == "video-poker" ? "active" : ""} to="/games/video-poker">
                  {__("Poker")}
                </Link>
              </li>
              <li>
                <Link className={category == "baccarat" ? "active" : ""} to="/games/baccarat">
                  {__("Baccarat")}
                </Link>
              </li>
              <li>
                <Link className={category == "other" ? "active" : ""} to="/games/other">
                  {__("Other")}
                </Link>
              </li>
            </ul>
          </div>
        )}

      <div className="mobile_search_area">{/* <SearchBox {...props} /> */}</div>

      <div className={"game_list " + category}>
        {props.gameList.map((game, index) => (
          <GameItem {...props} game={game} key={index} setModalLoginPropsData={props.setModalLoginPropsData} />
        ))}
      </div>

      {props.showPagination && (
        <>
          <br />
          <br />
          <LoadMore data={props.pagination} onPageChange={props.onPageChange} />
          <div className="gameLoadingProgress">
            <div className="gameLoadingInfo">
              {__("You've viewed")} {props.gameLoaded} {__("of")} {props.totalGame} {__("games")}
              {/* <div className="gameLoaded">{props.gameLoaded}</div>
                <div className="totalGame"> {props.totalGame}</div> */}
            </div>

            <ProgressBar variant="success" now={(props.gameLoaded / props.totalGame) * 100} />
          </div>
        </>
      )}

      <Modal size="lg" show={providersModal} onHide={closeAllModals}>
        <Modal.Body className="providers_modal">
          <div onClick={closeAllModals} className="modal_close">
            <i className="fal fa-times"></i>
          </div>

          <div className="modal_headline">{__("Filter By Providers")}</div>
          <div className="providers_list">
            <Link
              onClick={clearAllProviders}
              className={"common_btn inactive small " + (selectedProviders.length === 0 ? "active" : "")}
            >
              {__("All Providers")}
            </Link>
            {providers.map((item, index) => (
              <Link
                onClick={() => toggleSelectedProvider(item.provider_id)}
                key={index}
                className={
                  "common_btn inactive small " + (selectedProviders.indexOf(item.provider_id) !== -1 ? "active" : "")
                }
              >
                {item.name}
              </Link>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
