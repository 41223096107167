import { Accordion } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SelectBox from "elements/SelectBox";
import InputBox from "elements/InputBox";
import Pagination from "elements/Pagination";
import DateSelector from "elements/DateSelector";
import NoRecordsFound from "elements/NoRecordsFound";
import CategoryGames from "elements/games_elements/CategoryGames";
import GameItem from "elements/games_elements/GameItem";
import api from "services/api";
import { __ } from "services/Translator";

export default function FavoriteGames(props) {
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.post("/games/favorites", form);
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  return (
    <div className="dashboard_content history_area_full">
      <div className="right_area table_area p-3">
        <div className="left_area">
          <h3 className="tabName">{__("My Games")}</h3>
        </div>
        <div className="d-block game_list">
          {!!results.length ? (
            <>
              <div className="game_list">
                {results.map((game, index) => (
                  <GameItem
                    game={game}
                    key={index}
                    showAlert={props.showAlert}
                    isAuthenticated={props.isAuthenticated}
                    {...props}
                  />
                ))}
              </div>

              <br />
              <br />
              <Pagination data={pagination} onPageChange={onPageChange} />
            </>
          ) : (
            <NoRecordsFound content={__("Looks like you did not add any favorite games.")} />
          )}
        </div>

        <br />
      </div>
    </div>
  );
}
