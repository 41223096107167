import React, { useState, useEffect } from "react";
import api from "services/api";
import ls from "services/ls";
import auth from "services/auth";
import ScrollTo from "elements/ScrollTo";
import Header from "elements/Header";
import Footer from "elements/Footer";
import Spinner from "elements/Spinner";
import Swal from "sweetalert2";
import Chat from "elements/Chat";
import PreLeftSidebar from "elements/PreLeftSidebar";
import { useLocation } from "react-router-dom";
import { isMobile, isMobileByViewPort, cauculateLandScape } from "services/isMobile";
import LoginReg from "elements/LoginReg";
import StickyFooterMenu from "elements/StickyFooterMenu";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import packageJson from "base/package.json";
import moment from "moment";
import { Helmet } from "react-helmet";

const FullLayout = ({ children, ...rest }) => {
  const location = useLocation();

  const [spinner, setSpinner] = useState(false);

  const [isLandscape, setIsLandscape] = useState(cauculateLandScape());
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isMobileDeviceByViewPort, setIsMobileDeviceByViewPort] = useState(false);

  const showAlert = (title, message, isError, callback, timeout) => {
    var config = {
      title: title,
      text: message,
    };
    if (timeout) {
      config.timer = timeout;
    }
    if (isError) {
      config.iconHtml = "<img src='/smiley-sad-bold-svgrepo-com.svg' />";
      config.customClass = {
        icon: "swal-no-border-icon",
      };
    } else {
      config.icon = "success";
    }
    Swal.fire(config).then((value) => {
      if (callback) {
        callback(value);
      }
    });
  };

  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [userDetails, setUserDetails] = useState(auth.getUser);

  //Get user balance functionality
  const [userBalance, setUserBalance] = useState({
    cash_balance: 0,
    bonus_balance: 0,
    total_balance: 0,
    wallets: [],
    rates: [],
  });
  const getUserBalance = async (ev) => {
    var response = await api.get("/get-balance");
    console.log(response);
    ls.set("conversionRates", response.data.rates);
    setUserBalance(response.data);
  };

  const setUsersWallet = async (currency) => {
    var response = await api.post("/set-wallet", { currency });
    console.log(response);
    if (response.status === 200) {
      setUserBalance(response.data);
      ls.set("conversionRates", response.data.rates);
    }
  };

  const [messageCount, setMessageCount] = useState(0);
  const getMessageCount = async (ev) => {
    var response = await api.get("/player/messages-and-notifications-count");
    if (response.status === 200) {
      setMessageCount(response.data);
    }
  };

  const [freeSpinCount, setFreeSpinCount] = useState(0);
  const getFreeSpinCount = async (ev) => {
    var response = await api.get("/player/unused-free-spins-count");
    if (response.status === 200) {
      setFreeSpinCount(response.data);
    }
  };

  const [accountProgress, setAccountProgress] = useState(0);
  const getAccountProgress = async (ev) => {
    var response = await api.get("/account-progress");
    console.log("accountProgress", response);
    setAccountProgress(response.data);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsLandscape(cauculateLandScape());
    });
    setIsMobileDevice(isMobile());
    setIsMobileDeviceByViewPort(isMobileByViewPort());

    var interval;
    if (isAuthenticated) {
      getAccountProgress();
      getUserBalance();
      setUserDetails(auth.getUser());
      getMessageCount();
      getFreeSpinCount();
    }

    interval = setInterval(() => {
      if (isAuthenticated) {
        getUserBalance();
        getMessageCount();
        getFreeSpinCount();
      }
    }, 15000);
    return () => {
      try {
        clearInterval(interval);
      } catch (ex) {}
    };
  }, []);

  const [searchStr, setSearchStr] = useState("");
  const [providers, setProviders] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const getProviders = async (gameTypes) => {
    var url = "/games/providers";
    if (gameTypes !== undefined) {
      url += "/" + gameTypes + "/0";
    }

    var response = await api.get(url);
    if (response.status === 200) {
      setProviders(response.data);
    }
  };
  const [initialSettings, setInitialSettings] = useState([]);
  const getInitialSettings = async () => {
    var response = await api.get("/initial-settings");
    if (response.status === 200) {
      setInitialSettings(response.data);
    }
  };
  useEffect(() => {
    getProviders();
    getInitialSettings();
  }, []);

  //Get Total Jackpot
  const [totalJackpot, setTotalJackpot] = useState(0);
  const [totalJackpotReLoaded, setTotalJackpotReLoaded] = useState(Date.now());
  const getTotalJackpot = async (ev) => {
    var response = await api.get("/total-jackpot-amount");
    console.log(response);
    if (response.status === 200) {
      console.log("total-jackpot-amount", response.data.totalJackpot);
      // setUserBalance(response.data);
      setTotalJackpot(response.data.totalJackpot);
      //if total jackpot amount stays the same, it won't trigger the useEffect to re-set the dummy amount
      //We need a flag variable to trigger that
      setTotalJackpotReLoaded(Date.now());
    }
  };

  //JackpotTicker
  useEffect(() => {
    var interval;

    getTotalJackpot();

    interval = setInterval(() => {
      getTotalJackpot();
    }, 9000000);
    return () => {
      try {
        clearInterval(interval);
      } catch (ex) {}
    };
  }, []);

  const [supportSidebarOpen, setSupportSidebarOpen] = useState(false);
  const [modalSignUpPropsData, setModalSignUpPropsData] = useState(false);
  const [modalLoginPropsData, setModalLoginPropsData] = useState(false);
  const [showLeftSidebar, setShowLeftSidebar] = useState(false);
  // const [showPreLeftSidebar, setShowPreLeftSidebar] = useState(
  //   isMobile() ? false : true
  // );
  const [showPreLeftSidebar, setShowPreLeftSidebar] = useState(true);

  const [openWalletModalTab, setOpenWalletModalTab] = useState(null);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [chatInPopup, setChatInPopup] = useState(null);

  useEffect(() => {
    if (window.innerWidth > 1199) {
      setShowPreLeftSidebar(true);
    } else {
      setShowPreLeftSidebar(false);
    }
  }, []);

  const language = ls.get("language");
  var [direction, setDirection] = useState("ltr");

  React.useEffect(() => {
    if (language && language.direction && language.direction === "rtl") {
      setDirection("rtl");
    }
  }, [language]);

  const passableParams = {
    showAlert,
    isMobileDevice,
    isMobileDeviceByViewPort,
    isLandscape,
    isAuthenticated,
    spinner,
    setSpinner,
    userDetails,
    userBalance,
    getUserBalance,
    setUsersWallet,
    accountProgress,
    getAccountProgress,
    searchStr,
    setSearchStr,
    providers,
    getProviders,
    selectedProviders,
    setSelectedProviders,
    supportSidebarOpen,
    setSupportSidebarOpen,
    modalSignUpPropsData,
    setModalSignUpPropsData,
    modalLoginPropsData,
    setModalLoginPropsData,
    showLeftSidebar,
    setShowLeftSidebar,
    openWalletModalTab,
    setOpenWalletModalTab,
    isChatVisible,
    setIsChatVisible,
    initialSettings,
    showPreLeftSidebar,
    setShowPreLeftSidebar,
    totalJackpot,
    setTotalJackpot,
    totalJackpotReLoaded,
    // notifictionCount,
    // setNotifictionCount,
    messageCount,
    setMessageCount,
    direction,
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, passableParams);
    }
    return child;
  });

  const [inPlay, setInPlay] = useState(false);
  const playPath = location.pathname.split("/");

  useEffect(() => {
    setShowLeftSidebar(false);
  }, [location.pathname]);

  useEffect(() => {
    setInPlay(playPath.includes("play"));
  }, [playPath]);
  return (
    <>
      <Helmet>
        <html dir={direction} />
        <body className={"dir_" + direction} />
      </Helmet>
      {spinner && <Spinner />}
      <GoogleReCaptchaProvider reCaptchaKey="6LeS_4MfAAAAAFLVe_-eLMC-ozusZM6VtZm_w3NP">
        <LoginReg {...passableParams} />
        <div className={"home " + (isChatVisible && isAuthenticated ? "chat_open" : "") + (inPlay ? " in_play" : "")}>
          {(isMobileDevice && inPlay) || <Header {...passableParams} />}

          <div className="container-fluid container-xxl blackBg">
            <div className={"row " + (isMobileDevice ? "" : "")}>
              {/* <div className="d-none d-md-block col-md-3 col-xl-2">
            <LeftSide {...passableParams} />
          </div> */}
              {/* <div className="col-xxl-2 col-lg-3 col-md-3">
                <PreLeftSidebar {...passableParams} />
              </div> */}
              <div className="col-12 col-sm-12 col-md-12 col-xl-12">
                {/* <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xxl-10"> */}
                <main className={inPlay ? "in_play" : ""}>
                  <div>
                    <PreLeftSidebar {...passableParams} />
                  </div>
                  <div>{childrenWithProps}</div>
                </main>

                {(isMobileDevice && inPlay) || <Footer {...passableParams} />}
              </div>
            </div>
          </div>
        </div>
        {isMobileDevice && (!inPlay || !isLandscape) && <StickyFooterMenu {...passableParams} />}
        <span className="d-none">{moment(packageJson.buildDate).format("DD-MM-YYYY HH:MM")}</span>
        <ScrollTo />
        {isAuthenticated && <Chat {...passableParams} chatInPopup={chatInPopup} setChatInPopup={setChatInPopup} />}
      </GoogleReCaptchaProvider>
    </>
  );
};

export default FullLayout;
