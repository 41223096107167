import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useParams } from "react-router-dom";
import api from "services/api";
import auth from "services/auth";
import ls from "services/ls";
import config from "configs/config";
import InputBox from "elements/InputBox";
import { Accordion } from "react-bootstrap";
import { __ } from "services/Translator";
import DOMPurify from "dompurify";

export default function TicketDetails(props) {
  const params = useParams();
  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const userBalance = props.userBalance;

  const [reply, setReply] = useState("");
  const updateReply = (ev) => {
    setReply(ev.target.value);
  };

  const [ticket, setTicket] = useState("");
  const getTicket = async () => {
    props.setSpinner(true);
    var response = await api.get("/ticket/" + params.id + "/details");
    props.setSpinner(false);
    if (response.status === 200) {
      setTicket(response.data);
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  const replyToTicket = async () => {
    props.setSpinner(true);
    var response = await api.post("/ticket/" + ticket.id + "/reply", {
      reply,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      props.showAlert("Success!!", response.data.message);
      getTicket();
      setReply("");
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  useEffect(() => {
    getTicket();
  }, []);

  return (
    <>
      <section className="content_section ticket_details_container">
        {ticket != '' && (
          <>
            <div className="common_heading_area">
              <h1 className="common_title">
                {__("Ticket") + "#" + ticket.id + "-" + ticket.title}
                <span
                  className="ticket_status  badge badge-success"
                  style={{
                    fontSize: "12px",

                    marginLeft: "10px",
                    position: "relative",
                    top: "-4px",
                  }}
                >
                  {ticket.status === 3
                    ? __("Pending")
                    : ticket.status === 4
                    ? __("Solved")
                    : ticket.status === 5
                    ? __("Closed")
                    : __("Open")}
                </span>
              </h1>
            </div>
            <div className="tickets_list">
              <div className="single_ticket">
                <p>
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(ticket.body),
                    }}
                  ></strong>
                  <div className="ticket_date">{ticket.created_at}</div>
                </p>
                {!!ticket.comments && !!ticket.comments.length && (
                  <p>
                    <br />
                    <h5>{__("Comments")}</h5>
                    <ul className="conversation_list">
                      {ticket.comments.map((comment, index) => (
                        <li key={index}>
                          <div
                            className="reply_text"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(comment.body),
                            }}
                          ></div>
                          <div className="reply_date">
                            {comment.created_at} {__("by")}{" "}
                            {comment.author.name}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </p>
                )}

                {(ticket.status === 1 || ticket.status === 2) && (
                  <div className="conversation_reply_area">
                    <div className="replyFrom">
                      <div className="reg-field">
                        <textarea
                          className="form-controll input_box"
                          name="reply"
                          rows="4"
                          value={reply}
                          onChange={updateReply}
                          placeholder={__("Reply")}
                        ></textarea>
                      </div>
                      <div className="reg-submit">
                        <br />
                        <button
                          onClick={replyToTicket}
                          className="common_btn common_brown_button"
                        >
                          {__("Reply")}
                        </button>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}s
      </section>
    </>
  );
}
