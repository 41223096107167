import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useHistory, useParams, useLocation } from "react-router-dom";
import auth from "services/auth";
import Spinner from "elements/Spinner";
import AppContext from "contexts/AppContext";
import api from "services/api";
import ls from "services/ls";
import CheckBox from "elements/CheckBox";
import emailValidate from "services/emailValidate";
// import passwordValidate from "../services/passwordValidate";
import Translator, { __ } from "services/Translator";
import RegCampaigns from "elements/RegCampaigns";

import validator from "validator";

import DateSelector from "elements/DateSelector";
import LanguageSelector from "elements/LanguageSelector";
import config from "configs/config";

// import SocialLogin from "./SocialLogin";
import { Modal, Button, Alert, Dropdown } from "react-bootstrap";
import SelectBox from "./SelectBox";
// import { format } from "date-fns";

const LoginReg = (props) => {
  const params = useParams();
  const { search } = useLocation();
  const history = useHistory();
  const [spinner, setSpinner] = useState(false);
  // loginModal
  const [showLoginModal, setShowLoginModal] = useState(false);
  const openLoginModal = () => {
    setShowLoginModal(true);
    setActiveTab("login");
    setLoginForm({ email: "", password: "" });
    // setShowSidebar(false);
  };
  const closeLoginModal = () => {
    setShowLoginModal(false);
  };

  //SignUp Modal
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const openSignUpModal = () => {
    // setShowSignUpModal(true);
    setActiveTab("Sign up");
    setShowLoginModal(true);
    // setShowSidebar(false);
  };
  const closeSignUpModal = () => {
    setShowSignUpModal(false);
  };

  // tabs
  const [activeTab, setActiveTab] = useState("login");
  const toggleTab = (tabName) => {
    setActiveTab(tabName);
  };

  // recoverModal
  const [recoverPssModal, setRecoverPssModal] = useState(false);
  const openRecoverPssModal = () => {
    setRecoverPssModal(true);
    setShowLoginModal(false);
    setSentForgotPassMail(false);
    setForGotErrors("");
    setForgotPassForm({ email: "" });
  };
  const closeRecoverPssModal = () => {
    setRecoverPssModal(false);
  };

  const [alertMsg, setAlertMsg] = useState({
    message: "Successfully added",
    variant: "success",
    showAlert: false,
  });

  const { updateUserObj } = React.useContext(AppContext);
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [userType, setUserType] = useState(auth.getUserType);
  const [modalForgotPassword, setModalForgotPassword] = useState(props.modalForgotPassCall);

  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  const [action, setAction] = useState(props.action ? props.action : "signup"); //signup, login depending on account exsitance
  const [selectedMenu, setSelectedMenu] = useState(props.selectedMenu);

  const [modalLogin, setModalLogin] = useState(props.modalLoginCall);
  const [modalLoginCall, setModalLoginCall] = useState(false);

  const [initModalLoginProps, setInitModalLoginProps] = useState({
    loging_modal: false,
    forgot_pass_modal: false,
  });

  const [loginData, setLoginData] = useState({
    loging_modal: false,
    forgot_pass_modal: false,
  });

  const switchToLogin = () => {
    closeAllModals();
    // setModalLogin(true);
    setShowLoginModal(true);
  };

  const switchToForgotPassword = () => {
    closeAllModals();
    setModalForgotPassword(true);
    setSentForgotPassMail(false);
  };

  const closeAllModals = () => {
    // setModalLogin(false);
    setShowLoginModal(true);
    setModalForgotPassword(false);
  };

  useEffect(() => {
    if (props.modalLoginPropsData == true) {
      setShowLoginModal(props.modalLoginPropsData);
      setActiveTab("login");
      setModalForgotPassword(props.modalForgotPasswordPropsData);
    }

    console.log("props.modalSignUpPropsData", props.modalSignUpPropsData);
    if (props.modalSignUpPropsData == true) {
      setShowLoginModal(props.modalSignUpPropsData);
      setActiveTab("Sign up");
      setModalForgotPassword(props.modalForgotPasswordPropsData);
    }
  }, [props]);

  const updateEmail = (ev) => {
    setEmail(ev.target.value);
  };
  const updatePass = (ev) => {
    setPass(ev.target.value);
  };

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [mainErrorMgs, setMainErrorMgs] = useState("");

  const updateForm = (field, value) => {
    setLoginForm({ ...loginForm, [field]: value });
  };

  const updateInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    updateForm(field, value);
  };

  const updateSelect = (a, b) => {
    var field = b.name;
    var value = a.value;
    updateForm(field, value);
  };

  const validateForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};

    // if (!emailValidate(loginForm.email)) {
    //   errorsTmp = { ...errorsTmp, email: "Please enter a valid email address" };
    //   valid = false;
    // }

    if (loginForm.email.length < 2 || loginForm.email.length > 250) {
      errorsTmp = { ...errorsTmp, email: __("Please enter your email") };
      valid = false;
    }

    if (loginForm.password.length < 2 || loginForm.password.length > 250) {
      errorsTmp = { ...errorsTmp, password: __("Please enter your password") };
      valid = false;
    }

    setErrors(errorsTmp);

    return valid;
  };

  const login = async (ev) => {
    ev.preventDefault();
    var valid = validateForm();
    setMainErrorMgs("");
    if (valid) {
      setSpinner(true);
      var response = await api.post("/login", loginForm);
      console.log(response);
      setSpinner(false);
      if (response.status == 200) {
        await auth.login(response.data.user);
        await updateUserObj(response.data.user);
        // setRegistrationSuccessModal(true);
        setShowLoginModal(false);
        setIsAuthenticated(auth.isAuthenticated);
        props.showAlert(__("Welcome"), __("Let's go play!"), false, null, 5000);
      } else {
        props.showAlert(__("Login Failed"), response.data.message, true, null);
      }
    }
  };

  //Getting necessary data for registration START'S
  const [allCountries, setAllCountries] = useState(null);
  const getAllCountries = async () => {
    var response = await api.get("/countries");
    if (response.status === 200 && response.data) {
      setAllCountries(response.data);
    }
  };

  const [languages, setLanguages] = useState([]);

  const [selectedLanguage, setSelectedLanguage] = useState({});

  const getSelectedLanguage = async () => {
    var lang = await Translator.getSelectedLanguage();
    setSelectedLanguage(lang);
    updateRegistrationForm("language", lang.code);
  };

  useEffect(() => {
    getSelectedLanguage();
  }, []);

  const getLanguages = async () => {
    var response = await api.get("/languages");
    if (response.status === 200 && response.data) {
      setLanguages(response.data);
    }
  };

  const [currencies, setCurrencies] = useState([]);
  const [forcedSelectedCurrency, setForcedSelectedCurrency] = useState(null);
  const getCurrencies = async () => {
    var response = await api.get("/currencies");
    if (response.status === 200 && response.data) {
      var currecnyArray = [];
      response.data.forEach((el) => {
        if (el?.selected) {
          setForcedSelectedCurrency(el.currency_code);
          updateCustomeIpute(el.currency_code, "currency");
          setAcceptField({ ...acceptField, currency: true });
        } else {
          setForcedSelectedCurrency(false);
        }
        currecnyArray.push({
          label: el.currency_name,
          value: el.currency_code,
        });
      });
      setCurrencies(currecnyArray);
    }
  };

  useEffect(() => {
    getAllCountries();
    getLanguages();
    getCurrencies();
    // getRegCampaigns();
    console.log("asdfasdfasdfasdf", props.countries);
  }, []);

  //Getting necessary data for registration END'S

  //Register functionality START'S here
  const [activeStep, setActiveStep] = useState(1);
  const [showPass, setShowPass] = useState(false);
  const [showRePass, setShowRePass] = useState(false);
  const [regErrors, setRegErrors] = useState({});

  const [mandatory, setMandatory] = useState([
    "firstName",
    "lastName",
    "username",
    "email",
    "password",
    "bday",
    "bmonth",
    "byear",
    "phone",
  ]);

  const [registrationForm, setRegistrationForm] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    bday: "",
    bmonth: "",
    byear: "",
    code: "",
    referralCode: "",
    dateOfBirth: null,
    currency: "USD",
    language: selectedLanguage.code,
    bonusOffer: "no_bonus",
    promoCode: "",
    phone_number: "",
    phone_code: "",
    phone: "",
    phoneCode: "",
  });

  const [acceptField, setAcceptField] = useState({
    firstName: false,
    lastName: false,
    username: false,
    email: false,
    password: false,
    bday: false,
    bmonth: false,
    byear: false,
    code: false,
    referralCode: false,
    dateOfBirth: false,
    currency: false,
    language: false,
    bonusOffer: false,
    promoCode: false,
    phone_number: false,
    phone_code: false,
    phone: false,
    phoneCode: false,
  });

  const updateRegistrationForm = (field, value) => {
    setRegistrationForm({ ...registrationForm, [field]: value });
    console.log("updateRegistrationForm", registrationForm);
  };

  const updateRegInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    var type = target.type;
    if (type == "checkbox") {
      if (target.checked) {
        updateRegistrationForm(field, 1);
      } else {
        updateRegistrationForm(field, 0);
      }
    } else {
      updateRegistrationForm(field, value);
    }
    if (value.length > 2) {
      setAcceptField({ ...acceptField, [field]: true });
    } else {
      setAcceptField({ ...acceptField, [field]: false });
    }

    setRegErrors({
      ...regErrors,
      [field]: "",
    });

    remove(field);
    if (
      registrationForm.bday.length != 0 &&
      registrationForm.bmonth.length != 0 &&
      registrationForm.byear.length != 0
    ) {
      setRegErrors({
        ...regErrors,
        dateOfBirth: "",
      });
    }
  };

  const remove = (i) => {
    const filteredNames = mandatory.filter((n) => n !== i);
    setMandatory(filteredNames);
  };

  const [selectedPhoneCode, setSelectedPhoneCode] = useState([]);

  useEffect(async () => {
    if (props.initialSettings) {
      setSelectedPhoneCode([
        props.initialSettings?.ip_details?.dialing_code,
        props.initialSettings?.ip_details?.country_name,
        props.initialSettings?.ip_details?.ip_country,
      ]);
      setRegistrationForm({
        ...registrationForm,
        phoneCode: props.initialSettings?.ip_details?.dialing_code,
      });
      setAcceptField({ ...acceptField, phoneCode: true });
    }
  }, [props.initialSettings]);

  const selectPhoneCode = (ev) => {
    console.log("selectPhoneCode", ev);
    setSelectedPhoneCode(ev);
    setRegistrationForm({ ...registrationForm, phoneCode: ev[0] });
    setAcceptField({ ...acceptField, phoneCode: true });
    console.log("selectPhoneCodeselectPhoneCode", registrationForm);
  };

  const checkRegDuplicateEmail = async () => {
    // updateRegistrationForm(field, value);
    var response = await api.post("/check-reg-duplicate-email", {
      email: registrationForm.email,
    });
    if (response.status == 200) {
      return true;
    } else {
      return response.data.errors;
    }
  };

  const checkRegDuplicateUsername = async () => {
    // updateRegistrationForm(field, value);
    var response = await api.post("/check-reg-duplicate-username", {
      username: registrationForm.username,
    });
    if (response.status == 200) {
      return true;
    } else {
      return response.data.errors;
    }
  };

  const checkRegDuplicatePhone = async () => {
    // updateRegistrationForm(field, value);
    var response = await api.post("/check-reg-duplicate-phone", {
      phone_number: registrationForm.phone,
      phone_code: registrationForm.phoneCode,
    });
    if (response.status == 200) {
      return true;
    } else {
      return response.data.errors;
    }
  };

  // const currencies = [
  //   { label: __("Control Type"), value: null },
  //   { label: __("Automatic"), value: "automatic" },
  //   { label: __("Manual"), value: "manual" },
  // ];

  const [validate, setValidate] = useState(true);
  const validateRegisterForm = (step) => {
    var valid = true;
    // return valid;
    var errorsTmp = {};

    if (registrationForm.username.length < 2 || registrationForm.username.length > 250) {
      errorsTmp = { ...errorsTmp, username: __("Please enter the user name") };
      setActiveStep(1);
      valid = false;
    } else {
      var response = checkRegDuplicateUsername(registrationForm.email);
      var promise = Promise.resolve(response);
      promise.then(function (val) {
        if (val == true) {
          // setActiveStep(activeStep + 1);
        } else {
          errorsTmp = {
            ...errorsTmp,
            username: val.username,
          };
          setActiveStep(1);
          valid = false;
          setRegErrors(errorsTmp);
        }

        console.log("PromisePromisePromise", val);
      });
    }

    if (registrationForm.email.length < 2 || registrationForm.email.length > 250) {
      errorsTmp = { ...errorsTmp, email: __("Please enter your email") };
      setActiveStep(1);
      valid = false;
    } else if (!emailValidate(registrationForm.email)) {
      errorsTmp = {
        ...errorsTmp,
        email: __("Please enter a valid email address"),
      };
      setActiveStep(1);
      valid = false;
    } else {
      var response = checkRegDuplicateEmail(registrationForm.email);
      var promise = Promise.resolve(response);
      promise.then(function (val) {
        if (val == true) {
          // setActiveStep(activeStep + 1);
        } else {
          errorsTmp = {
            ...errorsTmp,
            email: val.email,
          };
          setActiveStep(1);
          valid = false;
          setRegErrors(errorsTmp);
        }

        console.log("PromisePromisePromise", val);
      });
    }

    if (registrationForm.password.length < 8 || registrationForm.password.length > 30) {
      errorsTmp = {
        ...errorsTmp,
        password: __("Your password must contain between 8 and 30 characters"),
      };
      valid = false;
    }

    if (step === 1) {
      if (valid == false) {
        setValidate(false);
      }
      setRegErrors(errorsTmp);
      return valid;
    }

    if (registrationForm.firstName.length < 2 || registrationForm.firstName.length > 250) {
      errorsTmp = {
        ...errorsTmp,
        firstName: __("Please enter your first name"),
      };
      valid = false;
    }

    if (registrationForm.lastName.length < 2 || registrationForm.lastName.length > 250) {
      errorsTmp = { ...errorsTmp, lastName: __("Please enter your last name") };
      valid = false;
    }

    if (registrationForm.phone.length < 2 || registrationForm.phone.length > 250) {
      errorsTmp = {
        ...errorsTmp,
        phone: __("Please enter your phone number"),
      };
      valid = false;
    } else {
      var response = checkRegDuplicatePhone();
      var promise = Promise.resolve(response);
      promise.then(function (val) {
        if (val == true) {
          // setActiveStep(activeStep + 1);
        } else {
          errorsTmp = {
            ...errorsTmp,
            phone: val.phone,
          };
          valid = false;
          setRegErrors(errorsTmp);
        }

        console.log("PromisePromisePromise", val);
      });
    }

    if (
      !registrationForm.dateOfBirth ||
      registrationForm.dateOfBirth.length < 10 ||
      registrationForm.dateOfBirth.length > 20
    ) {
      errorsTmp = { ...errorsTmp, dateOfBirth: "Please enter your birth day" };
      valid = false;
    } else {
      const dateStringRaw = registrationForm.dateOfBirth;
      // Create a Date object from the original date string
      const originalDate = new Date(dateStringRaw);
      // Format the date in the desired format (e.g., DD/MM/YYYY HH:mm)
      const dateString = `${originalDate.getMonth() + 1}/${originalDate.getDate()}/${originalDate.getFullYear()}`;

      var res = isOver18(dateString);
      if (!res) {
        errorsTmp = {
          ...errorsTmp,
          dateOfBirth: __("You are too young to create an account. Please leave this website."),
        };
        valid = false;
      }
    }

    if (!registrationForm.eighteenPlusConfirm) {
      errorsTmp = {
        ...errorsTmp,
        eighteenPlusConfirm: "Please confirm you are at least 18 years old",
      };
      valid = false;
    }
    if (valid == false) {
      setValidate(false);
    }
    setRegErrors(errorsTmp);
    return valid;
  };

  function isOver18(dateString) {
    const timestamp = Date.parse(dateString);
    console.log("dateStringdateString", dateString);
    if (isNaN(timestamp)) {
      // date string is not valid
      console.log("Invalid date");
    } else {
      const today = new Date();
      const birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age >= 18;
    }
    // console.log("dateStringdateString", dateString);

    // console.log("isOver18", age >= 18);
    // return age >= 18;
  }

  const regGoNextStep = () => {
    var valid = validateRegisterForm(1);
    if (valid) {
      setActiveStep(2);
    }
  };

  const registrationAction = async (ev) => {
    ev.preventDefault();
    var valid = validateRegisterForm(2);
    if (valid) {
      setSpinner(true);
      var newRegForm = JSON.parse(JSON.stringify(registrationForm));
      var affToken = ls.get("afftoken");
      var subid = ls.get("subid");
      if (affToken) {
        newRegForm.affiliateToken = affToken;
      }
      if (subid) {
        newRegForm.subid = subid;
      }
      var queryParams = ls.get("query_params");
      if (queryParams) {
        newRegForm.queryParams = queryParams;
      }

      newRegForm.phone_code = newRegForm.phoneCode;
      newRegForm.phone_number = newRegForm.phone;

      const dateStringRaw = registrationForm.dateOfBirth;
      // Create a Date object from the original date string
      const originalDate = new Date(dateStringRaw);
      // Format the date in the desired format (e.g., DD/MM/YYYY HH:mm)
      const dateString = `${originalDate.getMonth() + 1}/${originalDate.getDate()}/${originalDate.getFullYear()}`;
      newRegForm.dateOfBirth = dateString;

      var response = await api.post("/stake-signup", newRegForm);
      console.log(response.data);
      if (response.status == 200) {
        setSpinner(false);
        setShowLoginModal(false);
        await auth.login(response.data.user);
        await updateUserObj(response.data.user);
        // setRegistrationSuccessModal(true);
        setShowLoginModal(false);
        setIsAuthenticated(auth.isAuthenticated);
        props.showAlert(
          __("Welcome"),
          __("Let's go play!"),
          false,
          () => {
            window.location.href = "dashboard/cashier/deposit";
          },
          5000
        );

        // props.showAlert(
        //   __("Congratulations"),
        //   __("Please check your email to verify your email address.") +
        //     " " +
        //     __(
        //       "Please check your spam folder in case the message has not arrived in your inbox."
        //     )
        // );
        // setActiveTab("login");
        try {
          window.dataLayer.push({
            event: "Lead",
            user_id: response.data.player.id,
            email: response.data.player.player_email,
          });
        } catch (ex) {}
      } else {
        setSpinner(false);
        if (response.data.errors) {
          setRegErrors(response.data.errors);
        } else {
          alert(response.data.message);
        }
      }
    }
  };
  //Register functionality END'S here

  //forgot Password functionality START'S here
  const [forGotErrors, setForGotErrors] = useState("");
  const [forgotPassForm, setForgotPassForm] = useState({
    email: "",
  });
  const [sentForgotPassMail, setSentForgotPassMail] = useState("");

  const updateForgotInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    setForgotPassForm({
      email: value,
    });
  };

  const forgotPassword = async () => {
    var errorsTmp = {};

    if (!emailValidate(forgotPassForm.email)) {
      setForGotErrors("Please enter a valid email address");
    } else {
      setSpinner(true);
      var response = await api.post("/request-reset-password", forgotPassForm);
      setSpinner(false);
      console.log(response);
      if (response.status == 200) {
        // setRegistrationSuccessModal(true);
        setSentForgotPassMail(true);
      } else {
        setForGotErrors(response.data.message);
      }
    }
  };
  //forgot password functionality END'S here

  // const params = queryString.parse(window.location.search).p;

  //Referral functionality here
  const [referralCode, setReferralCode] = useState(params.referral_code);
  const getReferrerInfo = async () => {
    var response = await api.post("/get-referrer-info", {
      referralCode: referralCode,
    });
    if (response.status === 200) {
      setRegistrationForm({
        ...registrationForm,
        ["referralCode"]: response.data.referrer_info.referral_code,
      });
    }
  };

  useEffect(async () => {
    if (typeof referralCode !== "undefined") {
      setShowLoginModal(true);
      setActiveTab("Sign up");
      getReferrerInfo();
    }
  }, [referralCode]);

  useEffect(async () => {
    setActiveStep(1);
  }, [activeTab]);

  const d = new Date();
  let lastBdateYear = d.getFullYear() - 18;

  const updateBForm = (value, field) => {
    setRegistrationForm({ ...registrationForm, [field]: value });
  };

  const updateCustomeIpute = (value, field) => {
    setRegistrationForm({ ...registrationForm, [field]: value });
  };

  const currentDate = new Date();

  // Calculate the date 18 years ago
  const eighteenYearsAgo = new Date(currentDate);
  eighteenYearsAgo.setFullYear(currentDate.getFullYear() - 18);

  // Format the date (e.g., YYYY-MM-DD)
  const maxDate = `${eighteenYearsAgo.getFullYear()}-${(eighteenYearsAgo.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${eighteenYearsAgo.getDate().toString().padStart(2, "0")}`;

  return (
    <>
      {spinner && <Spinner />}
      <Modal size={activeTab == "Sign up" ? "lg" : ""} show={showLoginModal} onHide={closeLoginModal}>
        <Modal.Body className={activeTab === "Sign up" ? "login_modal reg_section" : "login_modal"}>
          {/* <pre>{JSON.stringify(registrationForm,null,2)}</pre> */}
          <div onClick={closeLoginModal} className="modal_close">
            <i className="fal fa-times"></i>
          </div>
          <div className="login_mode ">
            <Link
              to={"/" + search}
              onClick={() => toggleTab("login")}
              className={activeTab === "login" ? "mode_btn active" : "mode_btn"}
            >
              {__("login")}
            </Link>
            <Link
              to={"/" + search}
              onClick={() => toggleTab("Sign up")}
              className={activeTab === "Sign up" ? "mode_btn active" : "mode_btn"}
            >
              {__("Sign up")}
            </Link>
          </div>

          <Alert show={alertMsg.showAlert} variant={alertMsg.variant}>
            {alertMsg.message}
          </Alert>

          {activeTab === "login" && (
            <div className="form_area">
              <form onSubmit={login}>
                <div className="input_item">
                  <div className="name">
                    {__("Username")} {__("or")} {__("Email")}
                    <span className="requried_icon">*</span>
                  </div>
                  <input
                    type="text"
                    name="email"
                    value={loginForm.email}
                    onChange={updateInput}
                    class={"form-control name_value " + (errors.email ? "error" : "")}
                  />
                  <div className="errorMsg">{errors.email}</div>
                </div>
                <div className="input_item">
                  <div className="name">
                    {__("Password")}
                    <span className="requried_icon">*</span>
                    <span onClick={openRecoverPssModal} className="forgot_pass_link">
                      {__("Forgot?")}
                    </span>
                  </div>
                  <input
                    type="password"
                    name="password"
                    className={"form-control name_value " + (errors.password ? "error" : "")}
                    value={loginForm.password}
                    onChange={updateInput}
                  />

                  <div className="errorMsg">{errors.password}</div>
                </div>
                <div className="login_button">
                  <Button type="submit" className="login_btn">
                    {__("Log in")}
                  </Button>
                </div>
                <br />
                <div className="paragraph text-center headline d-none">
                  {__("Or")} {__("continue")} {__("with")}
                </div>
                <div className="social_buttons d-none">
                  <button className="social_btn">
                    <i className="fab fa-facebook"></i>
                  </button>
                  <button className="social_btn">
                    <i className="fab fa-google"></i>
                  </button>
                </div>
              </form>
            </div>
          )}

          {activeTab === "Sign up" && (
            <>
              <div className="row align-items-center ">
                <div className="col-md-4 text-center">
                  {selectedLanguage.code == "en" ? (
                    <img className="reg_promo_img" src={require("assets/images/promo/promo_en.png").default} />
                  ) : selectedLanguage.code == "pt" ? (
                    <img className="reg_promo_img" src={require("assets/images/promo/promo_pt.png").default} />
                  ) : (
                    <img className="reg_promo_img" src={require("assets/images/promo/promo_es.png").default} />
                  )}
                </div>
                <div className="col-md-8">
                  <form onSubmit={registrationAction}>
                    <div className="form_area">
                      {activeStep === 1 && (
                        <>
                          <div className="row">
                            <div className="col-6">
                              <div className="input_item">
                                <div className="name">
                                  {__("Username")}
                                  <span className="requried_icon">*</span>
                                </div>
                                <input
                                  type="text"
                                  name="username"
                                  placeholder={__("Username")}
                                  onChange={updateRegInput}
                                  value={registrationForm.username}
                                  className={
                                    "form-control name_value " +
                                    (regErrors.username ? "error " : "") +
                                    (acceptField.username ? "accepted" : "")
                                  }
                                />
                                <div className="errorMsg">{regErrors.username}</div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="input_item">
                                <div className="name">
                                  {__("Password")}
                                  <span className="requried_icon">*</span>
                                </div>
                                <input
                                  type={showPass ? "text" : "password"}
                                  name="password"
                                  placeholder={__("Password")}
                                  onChange={updateRegInput}
                                  value={registrationForm.password}
                                  className={
                                    "form-control name_value " +
                                    (regErrors.password ? "error " : "") +
                                    (acceptField.password ? "accepted" : "")
                                  }
                                />

                                {showPass && (
                                  <i
                                    className="show_hide_pass fa fa-eye-slash"
                                    onClick={() => {
                                      setShowPass(false);
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                )}
                                {!showPass && (
                                  <i
                                    className="show_hide_pass fa fa-eye"
                                    onClick={() => {
                                      setShowPass(true);
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                )}

                                <div className="errorMsg">{regErrors.password}</div>
                              </div>
                            </div>
                          </div>
                          <div className="input_item">
                            <div className="name">
                              {__("Email")}
                              <span className="requried_icon">*</span>
                            </div>
                            <input
                              type="email"
                              name="email"
                              placeholder={__("Email")}
                              onChange={updateRegInput}
                              value={registrationForm.email}
                              className={
                                "form-control name_value " +
                                (regErrors.email ? "error " : "") +
                                (acceptField.email ? "accepted" : "")
                              }
                            />
                            <div className="errorMsg">{regErrors.email}</div>
                          </div>
                        </>
                      )}
                      {activeStep === 2 && (
                        <>
                          <div className="row">
                            <div className="col-6">
                              <div className="input_item">
                                <div className="name">
                                  {__("First Name")}
                                  <span className="requried_icon">*</span>
                                </div>
                                <input
                                  type="text"
                                  name="firstName"
                                  placeholder={__("First Name")}
                                  onChange={updateRegInput}
                                  value={registrationForm.firstName}
                                  className={
                                    "form-control name_value " +
                                    (regErrors.firstName ? "error " : "") +
                                    (acceptField.firstName ? "accepted" : "")
                                  }
                                />
                                <div className="errorMsg">{regErrors.firstName}</div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="input_item">
                                <div className="name">
                                  {__("Last Name")}
                                  <span className="requried_icon">*</span>
                                </div>
                                <input
                                  type="text"
                                  name="lastName"
                                  placeholder={__("Last Name")}
                                  onChange={updateRegInput}
                                  value={registrationForm.lastName}
                                  className={
                                    "form-control name_value " +
                                    (regErrors.lastName ? "error " : "") +
                                    (acceptField.lastName ? "accepted" : "")
                                  }
                                />
                                <div className="errorMsg">{regErrors.lastName}</div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-8 col-md-12 col-lg-6">
                              <div className="input_item">
                                <div className="name">
                                  {__("Date of birth")}
                                  <span className="requried_icon">*</span>
                                </div>
                                <input
                                  className={
                                    "form-control name_value " +
                                    (regErrors.dateOfBirth ? "error " : "") +
                                    (acceptField.dateOfBirth ? "accepted" : "")
                                  }
                                  type="date"
                                  placeholder="DD/MM/YYYY"
                                  name="dateOfBirth"
                                  max={maxDate}
                                  onChange={updateRegInput}
                                  value={registrationForm.dateOfBirth}
                                />
                                {/* <div className="dob_input_area">
                                <input
                                  className={
                                    "form-control name_value " +
                                    (regErrors.dateOfBirth ? "error " : "") +
                                    (acceptField.dateOfBirth ? "accepted" : "")
                                  }
                                  type="number"
                                  placeholder="DD"
                                  name="bday"
                                  onChange={updateRegInput}
                                  value={registrationForm.bday}
                                  inputMode="numeric"
                                  pattern="[0-9]*"
                                />
                                <input
                                  className={
                                    "form-control name_value " +
                                    (regErrors.dateOfBirth ? "error " : "") +
                                    (acceptField.dateOfBirth ? "accepted" : "")
                                  }
                                  type="number"
                                  placeholder="MM"
                                  name="bmonth"
                                  onChange={updateRegInput}
                                  value={registrationForm.bmonth}
                                  inputMode="numeric"
                                  pattern="[0-9]*"
                                />
                                <input
                                  className={
                                    "form-control name_value " +
                                    (regErrors.dateOfBirth ? "error " : "") +
                                    (acceptField.dateOfBirth ? "accepted" : "")
                                  }
                                  type="number"
                                  placeholder="YYYY"
                                  name="byear"
                                  onChange={updateRegInput}
                                  value={registrationForm.byear}
                                  inputMode="numeric"
                                  pattern="[0-9]*"
                                />
                              </div> */}
                                {/* <div className="row">
                                <DateSelector
                                  className="filter_group"
                                  value={registrationForm.dateOfBirth}
                                  endYear={lastBdateYear}
                                  onChange={(itemValue) => {
                                    updateBForm(itemValue, "dateOfBirth");
                                    console.log("dateOfBirth", itemValue);
                                  }}
                                  reverseYear={true}
                                />
                              </div> */}

                                <div className="errorMsg">{regErrors.dateOfBirth}</div>
                              </div>
                            </div>

                            <div className="col-4 col-md-12 col-lg-6">
                              <div className="input_item">
                                <div className="name">
                                  {__("Currency")}
                                  <span className="requried_icon">*</span>
                                </div>
                                {forcedSelectedCurrency !== null && (
                                  <SelectBox
                                    className={
                                      "form-controll input_box select_box " + (acceptField.currency ? "accepted" : "")
                                    }
                                    placeholder={__("Currency")}
                                    minwidth={100}
                                    disabled={forcedSelectedCurrency}
                                    value={registrationForm.currency}
                                    onChange={(itemValue) => updateCustomeIpute(itemValue, "currency")}
                                    items={currencies}
                                  />
                                )}

                                <div className="errorMsg">{regErrors.currency}</div>
                              </div>
                            </div>
                          </div>
                          <div className="input_item">
                            <div className="row align-items-center">
                              <div className="col-4">
                                <div className="name">
                                  {__("Phone Code")}
                                  <span className="requried_icon">*</span>
                                </div>
                                <Dropdown className="flag_dropdown">
                                  <Dropdown.Toggle
                                    className={"flag_dropdown_btn " + (acceptField.phoneCode ? "accepted" : "")}
                                  >
                                    <div className="selectedPhoneCode">
                                      {selectedPhoneCode && (
                                        <>
                                          <img
                                            alt=""
                                            src={
                                              config.fileSourceUrl +
                                              "/images/flags/svg/" +
                                              selectedPhoneCode[2]?.toLowerCase() +
                                              ".svg"
                                            }
                                            className="country_flag"
                                            width="100"
                                          />{" "}
                                          {selectedPhoneCode[0]}
                                        </>
                                      )}
                                    </div>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="flag_dropdown_menu">
                                    {allCountries &&
                                      allCountries.map((value, key) => (
                                        <>
                                          <Dropdown.Item
                                            onClick={() => {
                                              selectPhoneCode(["+" + value.phonecode, value.nicename, value.code]);
                                            }}
                                          >
                                            <img
                                              alt=""
                                              src={
                                                config.fileSourceUrl +
                                                "/images/flags/svg/" +
                                                value.code?.toLowerCase() +
                                                ".svg"
                                              }
                                              className="country_flag"
                                              width="100"
                                            />{" "}
                                            +{value.phonecode} {value.nicename}
                                          </Dropdown.Item>
                                        </>
                                      ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                                {/* <div className="errorMsg">{regErrors.email}</div> */}
                              </div>
                              <div className="col-6">
                                <div className="name">
                                  {__("Phone Number")}
                                  <span className="requried_icon">*</span>
                                </div>
                                <input
                                  type="number"
                                  name="phone"
                                  placeholder={__("Phone Number")}
                                  onChange={updateRegInput}
                                  value={registrationForm.phone}
                                  className={
                                    "form-control name_value " +
                                    (regErrors.phone ? "error " : "") +
                                    (acceptField.phone ? "accepted" : "")
                                  }
                                  inputMode="numeric"
                                  pattern="[0-9]*"
                                />
                                <div className="errorMsg">{regErrors.phone}</div>
                              </div>
                              <div className="col-2">
                                <div className="name">&nbsp;</div>
                                <LanguageSelector
                                  selectedLanguage={registrationForm.language}
                                  value={registrationForm.language}
                                  onChange={(itemValue) => {
                                    updateCustomeIpute(itemValue, "language");
                                    console.log("itemValue", itemValue);
                                  }}
                                />
                              </div>
                              <div className="clearfix"></div>
                            </div>
                          </div>
                          <div className="row align-items-center">
                            <div className="col-md-12">
                              <div className="input_item">
                                <div className="name">
                                  {__("Referral Code")} ({__("Optional")})
                                </div>
                                <input
                                  type="text"
                                  name="code"
                                  placeholder={__("Code")}
                                  onChange={updateRegInput}
                                  value={registrationForm.code}
                                  className={
                                    "form-control name_value " +
                                    (regErrors.code ? "error " : "") +
                                    (acceptField.code ? "accepted" : "")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input_item">
                                <CheckBox
                                  type="checkbox"
                                  name="eighteenPlusConfirm"
                                  id="eighteenPlusConfirm"
                                  onClick={updateRegInput}
                                  checked={registrationForm.eighteenPlusConfirm}
                                >
                                  <label className="name">{__("Confirm that you are at least 18 years old")}.</label>
                                </CheckBox>
                                <div className="errorMsg">{regErrors.eighteenPlusConfirm}</div>
                              </div>
                            </div>
                            {/* <div className="col-md-2">
                           
                            <LanguageSelector
                              selectedLanguage={registrationForm.language}
                              value={registrationForm.language}
                              onChange={(itemValue) => {
                                updateCustomeIpute(itemValue, "language");
                                console.log("itemValue", itemValue);
                              }}
                            />
                          </div> */}
                          </div>{" "}
                          {/* <div className="reg_bouns_container">
                            <div className="reg_bouns_area">
                              <RegCampaigns
                                onChange={(value) => updateRegistrationForm("bonusOffer", value)}
                                onChangePromoCode={(value) => updateRegistrationForm("promoCode", value)}
                                promoCode={registrationForm.promoCode}
                                value={registrationForm.bonusOffer}
                                currency={registrationForm.currency}
                                language={registrationForm.language}
                              />
                            </div>
                          </div> */}
                        </>
                      )}

                      <div className="login_button">
                        {/* <pre style={{ color: "white" }}> {JSON.stringify(regErrors, null, 2)}</pre> */}
                        {activeStep === 1 ? (
                          <Button onClick={regGoNextStep} type="button" className="login_btn">
                            {__("Next")}
                          </Button>
                        ) : (
                          <div className="d-flex gap-2">
                            <Button
                              style={{ width: "33%" }}
                              onClick={() => setActiveStep(1)}
                              type="button"
                              className="login_btn grayBtn"
                            >
                              {__("Back")}
                            </Button>
                            <Button type="submit" className={"login_btn " + (mandatory.length > 0 ? "grayBtn" : "")}>
                              {__("Join Now")}
                            </Button>
                          </div>
                        )}
                        {validate == false && mandatory.length > 0 && (
                          <div className="error text-center">{__("Please fill in all mandatory fields.")}</div>
                        )}
                      </div>
                      <br />
                      <div className="paragraph text-center headline d-none">
                        {__("Or")} {__("continue")} {__("with")}
                      </div>
                      <div className="social_buttons d-none">
                        <button className="social_btn">
                          <i className="fab fa-facebook"></i>
                        </button>
                        <button className="social_btn">
                          <i className="fab fa-google"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}

          <div className="privacy_text">
            {__("By clicking Log In you are indicating that you have read and acknowledge the")}
            <Link to="/terms-and-conditions"> {__("Terms & Conditions")}</Link>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={recoverPssModal} onHide={closeRecoverPssModal}>
        <Modal.Body className="login_modal forgot_pass">
          <div onClick={closeRecoverPssModal} className="modal_close">
            <i className="fal fa-times"></i>
          </div>
          <div className="modal_headline">{__("Password Reset")}</div>
          {!sentForgotPassMail && (
            <>
              <div className="form_area">
                <div className="input_item">
                  <div className="name">
                    {__("Email")}
                    <span className="requried_icon">*</span>
                  </div>
                  <input
                    type="email"
                    name="email"
                    value={forgotPassForm.email}
                    onChange={updateForgotInput}
                    className={"form-control name_value " + (forGotErrors ? "error" : "")}
                  />
                  <div className="errorMsg">{__(forGotErrors)}</div>
                </div>

                <div className="login_button">
                  <Button onClick={forgotPassword} className="login_btn">
                    {__("Send Password Reset Link")}
                  </Button>
                </div>
              </div>
            </>
          )}

          {sentForgotPassMail && (
            <p className="recovery_success">
              {__("A password recovery email has been sent to your provided email address.")}
            </p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginReg;
