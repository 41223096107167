import React, { useState, useEffect } from "react";
import auth from "services/auth";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
import { __ } from "services/Translator";

const StickyFooterMenu = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [showWalletModal, setShowWalletModal] = useState(false);
  useEffect(() => {
    // alert(showWalletModal)
    if (showWalletModal == true) {
      props.showWalletModal(showWalletModal);
    }
  }, [showWalletModal]);

  //Search functionality
  const [showSearch, setShowSearch] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const updateSearchInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    setSearchVal(value);
  };

  // useEffect(() => {
  //   props.searchVal(searchVal);
  // }, [searchVal]);

  //GamePlay Page detection
  const location = useLocation();

  const [showSlotsLink, setShowSlotsLink] = useState(false);

  useEffect(() => {
    const playPath = location.pathname.split("/");
    setShowSlotsLink(playPath.includes("live-casino"));
  }, [location]);

  return (
    <div className="sticky_footer_menu_area">
      {/*
      <div className={"sticky_search_area " + (showSearch ? "showSearch" : '')} >
        <input onChange={updateSearchInput} name="search_value" value={searchVal} type="text" />
      </div>
      */}
      <ul>
        <li>
          <Link to="/">
            <i className="fas fa-home"></i>
            <div className="menu_text">{__("Home")}</div>
          </Link>
        </li>

        {isAuthenticated && (
          <>
            <li>
              <Link to="/dashboard/cashier">
                <i className="fas fa-wallet"></i>
                <div className="menu_text">{__("Cashier")}</div>
              </Link>
            </li>
          </>
        )}

        <li>
          {/* <Link to={{ pathname: "/", state: { focusSearch: true } }}>
            <i className="fas fa-search"></i>
            <div className="menu_text">{__("Search")}</div>
          </Link> */}
          <Link onClick={() => props.setShowPreLeftSidebar(true)}>
            <i className="fas fa-search"></i>
            <div className="menu_text">{__("Search")}</div>
          </Link>
        </li>
        
        {!isAuthenticated && (
          <>
            {showSlotsLink ? (
              <li>
                <Link to={{ pathname: "/games/slots" }}>
                  <svg className="svg_icon">
                    <use xlinkHref={require("assets/images/common/game_icons/game_icons.svg").default + "#slots"} />
                  </svg>
                  <div className="menu_text">{__("Slots")}</div>
                </Link>
              </li>
            ) : (
              <li>
                <Link to={{ pathname: "/games/live-casino" }}>
                  <svg className="svg_icon">
                    <use
                      xlinkHref={require("assets/images/common/game_icons/game_icons.svg").default + "#live_casino"}
                    />
                  </svg>
                  <div className="menu_text">{__("Live")}</div>
                </Link>
              </li>
            )}
          </>
        )}

        <li>
          <Link
            onClick={() => {
              props.setIsChatVisible(false);
              props.setSupportSidebarOpen(true);
            }}
            className="support_btn"
          >
            <img
              className="normalImg"
              style={{ margin: "0 auto 3px auto", width: "20px" }}
              src={require("../assets/images/icons/support-icon-white.svg").default}
            />

            <div className="menu_text">{__("Support")}</div>
          </Link>
        </li>

        {/* <li>
          <Link
            onClick={() => {
              props.setSupportSidebarOpen(true);
              // setTimeout(function () {
              //   props.setSupportSidebarOpen(false);
              // }, 500);
            }}
          >
            <i className="far fa-comment-dots"></i>
            <div className="menu_text">{__("Support")}</div>
          </Link>
        </li> */}
      </ul>
    </div>
  );
};

export default StickyFooterMenu;
