import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";

export default function Terms(props) {
  const params = useParams();
  const topic = params.topic;
  const [topicTitle, setTopicTitle] = useState("Player Terms");

  return (
   
      <div className="privacy_policy">
        <div className="terms_content">
          <div className="page_title">Privacy Policy</div>
          <div className="paragraph theme_color no_margin">
            1.1 Website Terms and Conditions ('Terms and Conditions')
          </div>
          <div className="paragraph">
            Please note that in these Terms and Conditions, 'You' or 'Your' or
            'User' or 'Player' means any person who uses the Software on this
            website and linked website(s). Unless otherwise stated, 'The Gaming
            Operator', ‘The Operator’, 'Dirk Casino', 'we' 'us' or 'our' refers
            collectively to dirkcasino.com and its subsidiaries, affiliates,
            directors, officers, employees, agents and contractors.
          </div>
          <div className="paragraph theme_color no_margin">
            1.2 Right to use Software
          </div>
          <div className="paragraph">
            You are permitted to (install where needed) use the Software and all
            content derived from the Software in connection with the use of the
            website(s) in accordance with these Terms & Conditions. You are
            permitted to use the Software / Website and all content derived from
            it in connection with the use of the website(s) in accordance with
            these Terms & Conditions and all games offered on this website by
            all Game Providers' End User License Agreements (EULA). The Software
            allows You to use our gambling services available via the website
            (the 'Services').
          </div>

          <div className="paragraph">
            This page defines the Terms and Conditions ("T&Cs") that apply to
            all monies and wagers accepted by us and all Services offered at
            www.dirkcasino.com ('the Website(s)').
          </div>
          <div className="paragraph theme_color no_margin">1.4 Use</div>

          <div className="paragraph uppercase">
            BY USING THE WEBSITE, YOU SIGNIFY YOUR AGREEMENT TO THESE TERMS AND
            CONDITIONS.
          </div>

          <div className="paragraph white"></div>
        </div>

        <div className="terms_content">
          <div className="page_title"></div>
          <div className="paragraph theme_color no_margin">
            2. Language used in Dirk Casino Terms and Conditions
          </div>
          <div className="paragraph">
            Dirk Casino Terms and Conditions as published on the Website (and
            updated from time to time) are in English and it is the English
            version of these Terms and Conditions that form the basis of these
            Terms and Conditions only. Translations into other languages may be
            made as a service and are made in good faith. However, in the event
            of ambiguity between the English version and a translation, the
            English version has priority over any translation.
          </div>
          <div className="paragraph theme_color no_margin">
            3. About Dirk Casino and dirkcasino.com
          </div>
          <div className="paragraph">
            Dirk Casino is operated by iRubix N.V., a company incorporated in
            Curacao with company registration number 154071 and having its
            registered address at Abraham de Veerstraat 9, Curacao. Dirk Casino
            is licensed and regulated by the Gaming Authority of Curacao.
          </div>

          <div className="paragraph">
            Chimp Bytes B.V. is registered in The Netherlands with company
            registration number 66454999, and is providing (credit) card payment
            services as an agent according to the service agreement concluded
            between iRubix N.V. and Chimp Bytes B.V. Skrill, Neteller,
            Paysafecard and Rapid Transfer transactions are processed by iRubix
            N.V.
          </div>

          <div className="paragraph">
            Dirk Casino is licensed by the Gaming Authority of Curacao under
            license number GLH-OCCHKTW0710242017 issued on October 24th 2017.
          </div>
          <div className="paragraph">
            If, for some reason, you are not satisfied with the resolution of
            your complaint by the support of Dirk Casino, you may report a
            complaint to Gaming Curacao per e-mail:
            compliance@gaming-curacao.com
          </div>
        </div>

        <div className="terms_content">
          <div className="page_title">Your Account</div>
          <div className="paragraph theme_color no_margin">4.1 Age</div>
          <div className="paragraph">
            All applicants must be over 18 years of age to register with Dirk
            Casino. We reserve the right to ask for Your proof of age and
            suspend Your account until satisfactory documentation is provided.
          </div>
          <div className="paragraph theme_color no_margin">
            4.2 Correspondence
          </div>
          <div className="paragraph">
            All information supplied when registering with Website must be
            accurate and complete in all respects. Dirk Casino reserves the
            right to confirm Your address by posting an address verification
            letter to You or by contacting You via e-mail. All letter
            correspondence will be discreet and the envelope will not display
            any reference to Dirk Casino. When such correspondence is
            initiated, all withdrawal requests will remain pending until the
            correspondence has been returned and approved by Dirk Casino.
          </div>

          <div className="paragraph theme_color no_margin">4.3.1 Account</div>

          <div className="paragraph">
            You may only open and operate a single account. If You hold more
            than one account we reserve the right to suspend these accounts
            until all the account details and balances (belonging to You) are
            consolidated. Once completed, all other accounts will be terminated
            leaving a single active account.
          </div>

          <div className="paragraph theme_color no_margin">4.4 Accuracy</div>

          <div className="paragraph">
            You are asked to keep Your registration details up to date. If You
            change Your address, email, phone number or any other contact or
            personal information please contact us in order to update Your
            account information.
          </div>
        </div>

        <div className="terms_content">
          <div className="page_title"></div>
          <div className="paragraph theme_color no_margin">4.5 Password</div>
          <div className="paragraph">
            You are asked to keep this information secret. Any actions carried
            out through Your account will stand if Your user name and password
            have been entered correctly.
          </div>
          <div className="paragraph theme_color no_margin">
            4.6 Verification of Bank Details
          </div>
          <div className="paragraph">
            If using a credit or debit card, the Cardholder's name MUST be the
            same as the name used when registering with the site. Where this is
            not the case the account may be suspended. Where an account is
            suspended, You should contact Player Services for details of our
            verification process. Any withdrawals that are made by bank wire or
            cheque will only be payable to the name used when registering with
            the site and if a debit or credit card has been used to deposit
            funds the name must correspond to the name registered on the card.
          </div>

          <div className="paragraph theme_color no_margin">
            4.7 Updating Payment Details
          </div>

          <div className="paragraph">
            Updating or adding additional payment details may only be done by
            contacting our Support Team. It is Your responsibility to make
            Yourself aware of the terms upon which Your payments are accepted.
            You are solely responsible to keep abreast of any changes.
          </div>

          <div className="paragraph theme_color no_margin">4.8 Activation</div>

          <div className="paragraph">
            All applicants may be sent an email to the registered email address
            for account activation. All accounts may be activated through this
            email. If this is not completed, Dirk Casino reserves the right to
            suspend all activities on the account until the account details are
            verified.
          </div>
        </div>
      </div>
    
  );
}
