import { Accordion } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import SelectBox from "elements/SelectBox";
import InputBox from "elements/InputBox";
import Pagination from "elements/Pagination";
import DateSelector from "elements/DateSelector";
import NoRecordsFound from "elements/NoRecordsFound";
import config from "configs/config.js";
import api from "services/api";
import auth from "services/auth";
import { Overlay, Tooltip } from "react-bootstrap";
import { __ } from "services/Translator";

export default function ReferAndEarn(props) {
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [userDetails, setUserDetails] = useState(auth.getUser);
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };
  const [totalReceiedBonus, setTotalReceiedBonus] = useState(0);
  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.get("/get-referred-player");
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setTotalReceiedBonus(response.data.totalReceiedBonus);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  useEffect(() => {
    getResults();
  }, []);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  const [appUrl, setAppUrl] = useState(config.appUrl);
  const [referralUrl, setReferralUrl] = useState(
    appUrl + "/refer/" + userDetails.referralCode
  );

  const [show, setShow] = useState(false);
  const target = useRef(null);
  const copyReferralUrl = () => {
    var copyText = document.getElementById("referralUrl");
    // var copyText = document.getElementById("myInput");

    /* Select the text field */
    // copyText.select();
    // copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    setShow(true);
    const timer = setTimeout(() => {
      setShow(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  const [pageContent, setPageContent] = useState("");
  const getContentPage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "refer_and_earn",
    });

    if (response.status == 200) {
      setPageContent(response.data);
    }
  };

  useEffect(async () => {
    getContentPage();
  }, []);

  

  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <div className="dashboard_content history_area_full referal_area">
      <div className="right_area table_area p-3">
        <div className="left_area">
          <h3 className="tabName">{__("Refer And Earn")}</h3>
        </div>

        <div className="referal_url_area">
          <div className="input_label">
            {__("Copy below URL and send it to friends.")}
          </div>
          <div className="copy_url_area">
            <input
              className="form-controll input_box"
              type="text"
              readonly="readonly"
              name="referralUrl"
              id="referralUrl"
              value={referralUrl}
            />
            <a
              className="in_input_icon"
              onClick={copyReferralUrl}
              href="javascript:"
              ref={target}
              // onClick={() => setShow(!show)}
            >
              <i className="fas fa-copy"></i>
            </a>
            <Overlay target={target.current} show={show} placement="top">
              {(props) => (
                <Tooltip id="overlay-example" className="tooltip" {...props}>
                  {__("Copied")}
                </Tooltip>
              )}
            </Overlay>
          </div>
        </div>

        <div className="">
          {pageContent && renderHTML(pageContent.content_data.content)}
        </div>
      </div>
      <br />

      <div className="right_area table_area p-3">
        {/* <div className="d-none d-sm-block"> */}
        <div className="d-block">
          <div className=" common_headline_with_bg">
            {__(
              "Below are the Nicknames of the player referred by you to Zino Casino."
            )}
          </div>
          
          <div className="">
            <div className="data_row">
              <div className="item">{__("Name")}</div>
              <div className="item">{__("Registration Date")}</div>
              <div className="item">{__("FTD")}</div>
              <div className="item">{__("Received Bonus amount")}</div>
              <div className="item"><b>{__("Total Bonus Received")} : {totalReceiedBonus}</b></div>
              
            </div>

            {results.length ? (
              <>
                {results.map((item, index) => (
                  
                  <div key={index} className="data_row">
                    <div className="item">
                      <span className="action_icon">
                        <i className="far fa-check"></i>
                      </span>
                      {item.player_details.player_nickname}
                    </div>
                    <div className="item">
                      {item.player_details.registration_date}
                    </div>
                    <div className="item">
                      {item.deposit_count > 0 ? __("Yes") : __("No")}
                    </div>
                    <div className="item">{item.received_bonus_amount}</div>
                    
                  </div>
                ))}
              </>
            ) : (
              <NoRecordsFound />
            )}
          </div>
        </div>

        <div className="mobile_accordion d-none ">
          {results.length ? (
            <>
              <Accordion>
                {results.map((item, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{item.game?.game_name}</Accordion.Header>
                    <Accordion.Body>
                      <div className="accordion_content">
                        <div className="item">
                          <div className="input_label">{__("Game")}</div>
                          <div className="input_box bg_grey">
                            {item.game?.game_name}
                          </div>
                        </div>
                        <div className="item">
                          <div className="input_label">{__("Type")}</div>
                          <div className="input_box bg_grey">{item.type}</div>
                        </div>
                        <div className="item">
                          <div className="input_label">
                            {__("Balance Type")}
                          </div>
                          <div className="input_box bg_grey">
                            {item.using_bonus}
                          </div>
                        </div>
                        <div className="item">
                          <div className="input_label">{__("Freeround")}</div>
                          <div className="input_box bg_grey">
                            {item.is_freeround}
                          </div>
                        </div>
                        <div className="item">
                          <div className="input_label">{__("Amount")}</div>
                          <div className="input_box bg_grey">{item.amount}</div>
                        </div>

                        <div className="item">
                          <div className="input_label">
                            {__("Cash Balance Before")}
                          </div>
                          <div className="input_box bg_grey">
                            {item.balance_before}
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>

              <br />
              <br />
              <div className="pagination">
                <Pagination data={pagination} onPageChange={onPageChange} />
              </div>
            </>
          ) : (
            <NoRecordsFound />
          )}
        </div>
      </div>
    </div>
  );
}
