import React, { useState, useEffect, useContext, useRef } from "react";
import auth from "services/auth";
import ls from "local-storage";
import AppContext from "contexts/AppContext";
import api from "services/api";
import emailValidate from "services/emailValidate";
import { __ } from "services/Translator";
import {
  Modal,
  Button,
  Alert,
  Dropdown,
  Form,
  Overlay,
  Tooltip,
  Collapse,
  Accordion,
  Badge,
} from "react-bootstrap";
import {
  Redirect,
  Route,
  Link,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";
// custom component
import LeftSidebar from "elements/LeftSidebar";
import PreLeftSidebar from "elements/PreLeftSidebar";
import RightSidebar from "elements/RightSidebar";
import CashierModals from "elements/dashboard_elements/CashierModals";
import SupportSidebar from "elements/SupportSidebar";
import LanguageSwitcher from "elements/LanguageSwitcher";
import LoginReg, { loginData } from "elements/LoginReg";
import SearchBox from "elements/SearchBox";
import UserDropDownMenu from "elements/UserDropDownMenu";

const Header = (props) => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { updateUserObj } = useContext(AppContext);
  const gamesCategory = params.category;

  const isAuthenticated = props.isAuthenticated;
  const userBalance = props.userBalance;
  const getUserBalance = props.getUserBalance;

  const logout = async (ev) => {
    ev.preventDefault();
    await auth.logout();
    await updateUserObj();
  };

  // userMenu
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const toggleRightSidebar = () => {
    setRightSidebarOpen(!rightSidebarOpen);
  };

  const [searchStr, setSearchStr] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const searchTextBox = useRef(null);

  // expand searcbar
  const [expandSearchBar, setExpandSearchBar] = useState(false);

  const toggleExpandedSearchBar = (force) => {
    if (expandSearchBar) {
      setSearchSuggestions([]);
      if (force === true) {
        setSearchStr("");
        setExpandSearchBar(false);
      } else {
        if (searchStr.length === 0) {
          setExpandSearchBar(false);
        }
      }
    } else {
      setExpandSearchBar(true);
      searchTextBox.current.focus();
    }
  };

  const getSearchSuggestions = async () => {
    if (searchStr.length >= 2) {
      var response = await api.get("/games/search-suggestions", {
        query: searchStr,
        providers: props.selectedProviders,
        extensiveInfo: true,
      });
      if (response.status === 200) {
        setSearchSuggestions(response.data);
      }
    } else {
      setSearchSuggestions([]);
    }
  };

  useEffect(() => {
    getSearchSuggestions();
  }, [searchStr]);

  const doSearchByGameName = (text) => {
    setSearchStr("");
    props.setSearchStr(text);
    setSearchSuggestions([]);
    toggleExpandedSearchBar(true);
    history.push("/games/search");
  };

  const [defualtShowing, setDefaultShowing] = useState(false);

  useEffect(() => {
    // if (props.showLeftSidebar) {
    setDefaultShowing(props.showLeftSidebar);
    // }
  }, [props.showLeftSidebar]);

  const openLogin = () => {
    props.setModalLoginPropsData(true);
    setTimeout(function () {
      props.setModalLoginPropsData(false);
    }, 500);
  };
  const openSignup = () => {
    props.setModalSignUpPropsData(true);
    setTimeout(function () {
      props.setModalSignUpPropsData(false);
    }, 500);
  };

  useEffect(() => {
    var route = location.pathname;
    if (
      !isAuthenticated &&
      route !== "/account-activated" &&
      route.indexOf("/reset-password") !== 0 &&
      route.indexOf("/referral/") !== 0 &&
      route.indexOf("/signup") !== 0
    ) {
      // openLogin();
      openSignup();
    }
    if (
      !isAuthenticated &&
      (route.indexOf("/referral/") === 0 || route.indexOf("/signup") === 0)
    ) {
      openSignup();
    }
  }, [isAuthenticated]);

  return (
    <>
      <header>
        <div className="container-fluid container-xxl">
          <section
            className={"header " + (isAuthenticated ? " authenticated" : "")}
          >
            <ul className="header_menu desktop">
              <li className="header_logo">
                {props.showPreLeftSidebar == false && (
                  <>
                    <Link to="/">
                      <img
                        style={{ width: "160px" }}
                        src={require("../assets/images/logos/zino-logo-new.png").default}
                        alt=""
                      />
                    </Link>
                  </>
                )}
              </li>

              <li className="openMenuBar menuSearch">
                {props.showPreLeftSidebar == false && (
                  <Link
                    onClick={() => {
                      props.setShowPreLeftSidebar(true);
                      setDefaultShowing(false);
                      props.setIsChatVisible(false);
                      props.setSupportSidebarOpen(false);
                      // setTimeout(function () {
                      //   props.setShowPreLeftSidebar(false);
                      // }, 500);
                    }}
                  >
                    <i className="fas fa-bars"></i>
                  </Link>
                )}
                <SearchBox {...props} />
              </li>

              <li className="d-block user_area">
                {isAuthenticated && <CashierModals {...props} />}
              </li>

              <li className="user_area">
                <ul>
                  {!props.isMobileDeviceByViewPort && (
                    <li className="d-none">
                      <LanguageSwitcher />
                    </li>
                  )}
                  {isAuthenticated && (
                    <>
                      <li>
                        <Link
                          onClick={() => {
                            props.setIsChatVisible(false);
                            props.setSupportSidebarOpen(true);
                          }}
                          className="support_btn"
                        >
                          <img
                            className="normalImg"
                            src={
                              require("../assets/images/icons/support-icon-white.svg")
                                .default
                            }
                          />
                          <img
                            className="hoveredImg"
                            src={
                              require("../assets/images/icons/support-icon-green.svg")
                                .default
                            }
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="userNameBtn d-none"
                          onClick={() => {
                            props.setShowLeftSidebar(!props.showLeftSidebar);
                            if (props.isMobileDevice) {
                              props.setShowPreLeftSidebar(false);
                            }
                          }}
                        >
                          {/* <i className="fa fa-bars" aria-hidden="true"></i> */}
                          <i className="fal downarrow fa-caret-down"></i>
                          <span>
                            {props.userDetails.username &&
                            props.userDetails.username !== ""
                              ? props.userDetails.username
                              : props.userDetails.first_name}
                          </span>
                        </Link>
                        <UserDropDownMenu {...props} />
                      </li>
                      <li>
                        <div className=" open_chat">
                          <Dropdown>
                            <Dropdown.Toggle>
                              <img
                                className="mainIcon"
                                src={
                                  require("../assets/images/icons/chat-communication.svg")
                                    .default
                                }
                              />
                              <img
                                className="hoverIcon"
                                src={
                                  require("../assets/images/icons/chat-communication-gold.svg")
                                    .default
                                }
                              />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <div className="modal_top_arrow"></div>
                              <Dropdown.Item
                                onClick={() => {
                                  props.setIsChatVisible(false);
                                  props.setSupportSidebarOpen(true);
                                }}
                              >
                                {__("Support Chat")}
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  props.setIsChatVisible(true);
                                  props.setSupportSidebarOpen(false);
                                }}
                              >
                                {__("Players forum")}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </li>
                      {!!props.messageCount && (
                        <li>
                          <Link to="/dashboard/messages">
                            <i className="far fa-bell notification"></i>

                            <Badge className="count radius_25">
                              {props.messageCount}
                            </Badge>
                          </Link>
                        </li>
                      )}
                    </>
                  )}
                  <li>
                    <LanguageSwitcher {...props} />
                  </li>
                  {!isAuthenticated && (
                    <>
                      <li>
                        <Link
                          onClick={openLogin}
                          className="common_btn  LogInBtn"
                        >
                          {__("Log In")} | {__("Sign Up")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => {
                            props.setIsChatVisible(false);
                            props.setSupportSidebarOpen(true);
                          }}
                          className="support_btn"
                        >
                          <img
                            className="normalImg"
                            src={
                              require("../assets/images/icons/support-icon-white.svg")
                                .default
                            }
                          />
                          <img
                            className="hoveredImg"
                            src={
                              require("../assets/images/icons/support-icon-green.svg")
                                .default
                            }
                          />
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          onClick={() => {
                            props.setModalSignUpPropsData(true);
                            setTimeout(function () {
                              props.setModalSignUpPropsData(false);
                            }, 500);
                          }}
                          className="common_btn  signUpBtn"
                        >
                          {__("Sign Up")}
                        </Link>
                      </li> */}
                    </>
                  )}
                </ul>
                {/* {!isAuthenticated && (
                  <ul className="small_menu">                    
                    <li>
                      <Link to="/promotions">{__("Promotions")}</Link>
                    </li>
                    <li>
                      <Link to="/">{__("Home")}</Link>
                    </li>
                  </ul>
                )} */}
              </li>
            </ul>

            <div className="header_menu mobile">
              <div className="row">
                <div className="col-2 align-self-center openMenuBar">
                  <Link
                    onClick={() => {
                      props.setShowPreLeftSidebar(true);
                      setDefaultShowing(false);
                      // setTimeout(function () {
                      //   props.setShowPreLeftSidebar(false);
                      // }, 500);
                    }}
                  >
                    <i className="fas fa-bars"></i>
                  </Link>

                  {/* {props.isAuthenticated ? (
                    <CashierModals {...props} />
                  ) : (
                    <Link className="header_logo" to="/">
                      <img
                        src={require("../assets/images/logos/zino-logo-new.png").default}
                      />
                    </Link>
                  )} */}
                </div>
                <div className="col-7 align-self-center logo_container">
                  {/* {props.isAuthenticated && (
                    <Link className="header_logo" to="/">
                      <img
                        src={require("../assets/images/logos/zino-logo-new.png").default}
                      />
                    </Link>
                  )} */}
                  {props.isAuthenticated ? (
                    <CashierModals {...props} />
                  ) : (
                    <Link className="header_logo" to="/">
                      <img
                        src={require("../assets/images/logos/zino-logo-new.png").default}
                      />
                    </Link>
                  )}
                </div>
                <div className="col-3 align-self-center mobile_header_right">
                  {isAuthenticated ? (
                    <>
                      <UserDropDownMenu {...props} />
                    </>
                  ) : (
                    <>
                      <Link
                        onClick={openLogin}
                        className="common_btn  LogInBtn"
                      >
                        {__("Log In")}
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
        <RightSidebar
          userBalance={props.userBalance}
          userDetails={props.userDetails}
          accountProgress={props.accountProgress}
          rightSidebarOpen={rightSidebarOpen}
          toggleRightSidebar={toggleRightSidebar}
        />

        <LeftSidebar {...props} />
        {/* <PreLeftSidebar {...props} /> */}
        <SupportSidebar {...props} />
      </header>
    </>
  );
};

export default Header;
