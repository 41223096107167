import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useParams } from "react-router-dom";
import emailValidate from "services/emailValidate";
import LanguageSwitcher from "elements/LanguageSwitcher";
import api from "services/api";
import { Button, Modal } from "react-bootstrap";
import { __ } from "services/Translator";

const Footer = (props) => {
  const [showBonusModal, setShowBonusModal] = useState(false);
  const d = new Date();
  let year = d.getFullYear();

  useEffect(() => {
    setTimeout(() => {
      try {
        window.anj_8af40258_9bb6_4fba_ba6c_9a812f3a140a.init();
      } catch (ex) {}
    }, 2000);
  }, []);

  return (
    <>
      {!props.isAuthenticated && (
        <div className="present_area">
          <a href="/promotions">
            <img
              className="greenIcon"
              src={
                require("../assets/images/icons/present-icon-green.svg").default
              }
            />
            <img
              className="blueIcon"
              src={
                require("../assets/images/icons/present-icon-blue.svg").default
              }
            />
          </a>
        </div>
      )}

      <footer className="">
        <section className="footer">
          <div className="footer_grids">
            <div style={{ textAlign: "center" }} className="d-block d-md-none">
              <Link to="/">
                <img
                  style={{ width: "160px" }}
                  src={
                    require("../assets/images/logos/zino-logo-new.png").default
                  }
                  alt=""
                />
              </Link>
            </div>
            <div className="links_section">
              <div className="single_block d-none d-md-block">
                <ul></ul>
              </div>
              <div className="single_block linkEffect">
                <ul>
                  <li className="uppercase list_heading">
                    {__("information")}
                  </li>
                  <li>
                    <Link to="/about">
                      {__("About")} {__("Casino Zino")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/vip">{__("VIP")}</Link>
                  </li>
                  <li>
                    <Link to="/promotions">{__("Promotions")}</Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        props.setIsChatVisible(false);
                        props.setSupportSidebarOpen(true);
                        props.setShowPreLeftSidebar(false);
                      }}
                    >
                      {__("Support")}
                    </Link>
                  </li>

                  {props.isAuthenticated && (
                    <li>
                      <Link to="/store">{__("Store")}</Link>
                    </li>
                  )}
                  {props.isAuthenticated && (
                    <li>
                      <Link to="/rewards">{__("Rewards")}</Link>
                    </li>
                  )}
                  <li>
                    <a
                      target="_blank"
                      href="https://login.zinoaff.com/signup.php"
                    >
                      {__("Affiliates")}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="single_block linkEffect">
                <ul>
                  <li className="uppercase list_heading">{__("Legal Info")}</li>

                  <li>
                    <Link to="/terms-and-conditions">
                      {__("Terms and conditions")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">{__("Privacy policy")}</Link>
                  </li>
                  <li>
                    <Link to="/bonus-terms">{__("Bonus Terms")}</Link>
                  </li>
                  {/* <li>
                    <Link to="/aml-policy">{__("AML Policy")}</Link>
                  </li> */}
                  <li>
                    <Link to="/responsible-gaming">
                      {__("Responsible Gaming")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/aml-policy">{__("AML Policy")}</Link>
                  </li>
                </ul>
              </div>
              <div className="single_block linkEffect">
                <ul>
                  <li className="uppercase list_heading">{__("SERVICES")}</li>
                  {/* <li>
                    <Link to="/games/all"> {__("Casino Games")}</Link>
                  </li>                  
                  <li>
                    <Link to="/games/live-casino"> {__("Live Casino")}</Link>
                  </li>
                  <li>
                    <Link to="/games/live-casino"> {__("Virtual Games")}</Link>
                  </li>
                  <li>
                    <Link to="/games/live-casino"> {__("Popular Games")}</Link>
                  </li> */}
                </ul>
                <ul className="like_left_menu">
                  <li className={"slotsMenu "}>
                    <Link to="/games/slots">
                      <div className="icon">
                        {/* <img
                        className=""
                        src={
                          require("../assets/images/common/slots.svg").default
                        }
                      /> */}
                      </div>
                      <div className="text">{__("Slots")}</div>
                    </Link>
                  </li>
                  <li className={"liveCasinoMenu "}>
                    <Link to="/games/live-casino">
                      <div className="icon">
                        {/* <img
                        className=""
                        src={
                          require("../assets/images/common/live-casino.svg")
                            .default
                        }
                      /> */}
                      </div>
                      <div className="text">{__("Live Casino")}</div>
                    </Link>
                  </li>
                  <li className={"newGamesMenu "}>
                    <Link to="/games/new">
                      <div className="icon">
                        {/* <img
                        className=""
                        src={
                          require("../assets/images/common/new-games.svg")
                            .default
                        }
                      /> */}
                      </div>
                      <div className="text">{__("New Games")}</div>
                    </Link>
                  </li>
                  <li className={"casinoGamesMenu "}>
                    <Link to="/games/table-games">
                      <div className="icon">
                        {/* <img
                        className=""
                        src={
                          require("../assets/images/common/table-games.svg")
                            .default
                        }
                      /> */}
                      </div>
                      <div className="text">{__("Table Games")}</div>
                    </Link>
                  </li>
                  {/* <li className={"jackpotsMenu d-none"}>
                    <Link to="/games/jackpots">
                      <div className="icon">
                        <img
                        className=""
                        src={
                          require("../assets/images/common/jackpots.svg")
                            .default
                        }
                      /> 
                      </div>
                      <div className="text">{__("Jackpots")}</div>
                    </Link>
                  </li> */}
                  <li className="zinoExclusiveMenu d-none">
                    <Link to="/games/zino-exclusive">
                      <div className="icon"></div>
                      <div className="text">{__("Zino Exclusive")}</div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="single_block">
                <ul>
                  {/* <li className="uppercase list_heading">{__("company")}</li>
                  <li>When Lambo N.V.</li>
                  <li>Abraham de Veerstraat 9</li>
                  <li>Willemstad, Curacao</li>
                  <li>support@casinobull.io</li> */}
                  <li className="active_social_link">
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.instagram.com/zino_casino"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.facebook.com/Zino-Casino-114098383718493"
                        >
                          <i className="fab fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.youtube.com/channel/UCmBwohaStUJLPtfvBarVi4g"
                        >
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                    <br />
                    <br />
                    <br />
                    <Link to="/">
                      <img
                        style={{ width: "160px" }}
                        src={
                          require("../assets/images/logos/zino-logo-new.png")
                            .default
                        }
                        alt=""
                      />
                    </Link>
                    {/* <li>
                      <a target="_blank" href="https://twitter.com/">
                        <i className="fab fa-google-plus-g"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://twitter.com/">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li> */}

                    <div className="socialLinkContainger d-none">
                      <Link to="#">
                        <i className="fab fa-facebook"></i>
                      </Link>

                      <Link to="#">
                        <i className="fab fa-twitter"></i>
                      </Link>

                      <Link to="#">
                        <i className="fab fa-google-plus-g"></i>
                      </Link>
                      <Link to="#">
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </div>
                  </li>
                </ul>
                <br />
                <br />
                <Link
                  className="common_btn reversed_common_btn bonusBtn float-left d-none"
                  onClick={() => {
                    setShowBonusModal(true);
                  }}
                >
                  <span className="dollerIcons">
                    <img
                      src={
                        require("../assets/images/icons/dollar-white.svg")
                          .default
                      }
                    />
                  </span>
                  <span className="bonusText">{__("Bonus")}</span>
                </Link>
              </div>
            </div>
          </div>

          <div className="copyright_section">
            <div className="left"></div>
            <div className="right">
              <div className="brands ">
                <ul>
                  <li className="dotIgamingLogo d-none">
                    <a target="_blank" href="https://dot-igaming.com">
                      <img
                        className="solidImg brand_img"
                        src={
                          require("../assets/images/common/dot-igaming-solid-white.svg")
                            .default
                        }
                      />
                      <img
                        className="hoverImg brand_img"
                        src={
                          require("../assets/images/common/dot-igaming.svg")
                            .default
                        }
                      />
                    </a>
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      src={require("../assets/images/brands/vivo.svg").default}
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      src={
                        require("../assets/images/brands/bsg-logo-2019.svg")
                          .default
                      }
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      style={{ height: "64px", marginTop: "-17px" }}
                      src={
                        require("../assets/images/brands/playson.svg").default
                      }
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      style={{ height: "46px", marginTop: "-8px" }}
                      src={require("../assets/images/brands/leap.svg").default}
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      style={{ height: "80px", marginTop: "-26px" }}
                      src={
                        require("../assets/images/brands/spinomenal.svg")
                          .default
                      }
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      style={{ height: "55px", marginTop: "-13px" }}
                      src={
                        require("../assets/images/brands/tom-horn-gaming.svg")
                          .default
                      }
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      src={
                        require("../assets/images/brands/red-rake-gaming-casino.svg")
                          .default
                      }
                    />
                  </li>
                </ul>
                <ul>
                  <li>
                    <img
                      className="brand_img"
                      src={
                        require("../assets/images/brands/visa-logo.svg").default
                      }
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      src={
                        require("../assets/images/brands/mastercard-logo.svg")
                          .default
                      }
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      src={
                        require("../assets/images/brands/bank-transfer-logo.svg")
                          .default
                      }
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      src={
                        require("../assets/images/brands/verified-by-visa-logo.svg")
                          .default
                      }
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      src={
                        require("../assets/images/brands/bitcoin.svg").default
                      }
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      src={
                        require("../assets/images/brands/ethereum.svg").default
                      }
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      src={
                        require("../assets/images/brands/18-logo.svg").default
                      }
                    />
                  </li>

                  <li>
                    <a target="_blank" href="https://www.gamstop.co.uk">
                      <img
                        className="brand_img"
                        src={
                          require("../assets/images/brands/gam-stop-logo.svg")
                            .default
                        }
                      />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="copyright_content">
                <div className="dotIgamingLogo other_logos">
                  <a target="_blank" href="https://dot-igaming.com">
                    <img
                      className="solidImg brand_img"
                      src={
                        require("../assets/images/common/dot-igaming-solid-white.svg")
                          .default
                      }
                    />
                    <img
                      className="hoverImg brand_img"
                      src={
                        require("../assets/images/common/dot-igaming.svg")
                          .default
                      }
                    />
                  </a>
                </div>

                <div className="other_logos">
                  <img
                    alt=""
                    className="provably_fair_img"
                    src={
                      require("../assets/images/footer/provably-fair-gr.svg")
                        .default
                    }
                  />
                </div>

                <div className="other_logos">
                  <img
                    alt=""
                    className="responsible_gaming_img"
                    src={
                      require("../assets/images/footer/responsible-gaming-gr.svg")
                        .default
                    }
                  />
                </div>
                <div className="other_logos">
                  <img
                    className="plus_age_img"
                    alt=""
                    src={
                      require("../assets/images/footer/18-plus-age-gr.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="license_and_info_text">
                <div className="license_seal ">
                  {/* <iframe
                    style={{
                      width: "150px",
                      height: "50px",
                      border: "none",
                      margin: " 20px 0",
                      // marginLeft: "64px",
                      verticalAlign: "bottom",
                    }}
                    src="https://licensing.gaming-curacao.com/validator/?lh=1de65c27b5de9463e7d0ae6327560c65&template=tseal"
                  ></iframe> */}
                  <div
                    id="anj-8af40258-9bb6-4fba-ba6c-9a812f3a140a"
                    data-anj-seal-id="8af40258-9bb6-4fba-ba6c-9a812f3a140a"
                    data-anj-image-size="128"
                    data-anj-image-type="basic-small"
                    style={{
                      width: "75px",
                      // height: "50px",
                      border: "none",
                      margin: " 20px auto",
                      // marginLeft: "64px",
                      verticalAlign: "bottom",
                    }}
                  ></div>
                </div>
                <div className="company_info">
                  {__(
                    "Zino Casino is operated by Zino Technologies N.V., a company incorporated in Curacao with company registration number 161659 and having its registered address at Abraham de Veerstraat 9, Willemstad, Curacao."
                  )}
                  <br />
                  {__(
                    "Zino Casino is licensed and regulated by the Gaming Authority of Curacao under license number NEW-LICENSE123452022"
                  )}
                </div>
              </div>
              <div className="copyright_text">
                {__("All rights reserved")} {year} © {__("Zino Casino")}
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
