import React, { useState, useEffect, useRef } from "react";
import api from "services/api";
import { Collapse } from "react-bootstrap";
import { useHistory, Link, useLocation } from "react-router-dom";
import { __ } from "services/Translator";
import GameSuggestion from "elements/games_elements/GameSuggestion";

const SearchBox = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [searchStr, setSearchStr] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const searchTextBox = useRef(null);

  const [expandSearchBar, setExpandSearchBar] = useState(false);

  const toggleExpandedSearchBar = (force) => {
    if (expandSearchBar) {
      setSearchSuggestions([]);
      if (force === true) {
        setSearchStr("");
        setExpandSearchBar(false);
      } else {
        if (searchStr.length === 0) {
          setExpandSearchBar(false);
        }
      }
    } else {
      setExpandSearchBar(true);
      searchTextBox.current.focus();
    }
  };

  const getSearchSuggestions = async () => {
    if (searchStr.length >= 2) {
      var response = await api.get("/games/search-suggestions", {
        query: searchStr,
        providers: props.selectedProviders,
        extensiveInfo: true,
      });
      console.log("getSearchSuggestions", response.data);
      if (response.status === 200) {
        setSearchSuggestions(response.data);
      }
    } else {
      setSearchSuggestions([]);
    }
  };

  useEffect(() => {
    getSearchSuggestions();
  }, [searchStr]);
  const doSearchByGameName = (text) => {
    setSearchStr("");
    props.setSearchStr(text);
    setSearchSuggestions([]);
    toggleExpandedSearchBar(true);
    history.push("/games/search");
  };

  useEffect(() => {
    setSearchStr("");
    console.log("Location changed");
  }, [location]);

  return (
    <>
      <div className="searchbox">
        <input
          className="form-control"
          id="searchFocusInput"
          placeholder={__("Search...")}
          autoComplete="off"
          value={searchStr}
          onChange={(ev) => setSearchStr(ev.target.value)}
          type="text"
          ref={searchTextBox}
        />
        <span className="search-icon position-absolute">
          <i className="fas fa-search"></i>
        </span>

        {!!searchStr.length && <div onClick={() => setSearchStr("")} className="invisible_overlay"></div>}

        <Collapse style={{ zIndex: 3050 }} in={searchStr.length}>
          <div className="search_container">
            {/* <img
              className="caretUp"
              src={
                require("../assets/images/common/caret-up-solid.png").default
              }
            /> */}
            <ul className="search_suggestions">
              {searchSuggestions.map((item, index) => (
                <li
                  onClick={() => {
                    doSearchByGameName(item.game_name);
                    if (props.isMobileDevice) {
                      props.setShowPreLeftSidebar(false);
                    }
                  }}
                  key={index}
                >
                  <img
                    src={item.game_snapshot_url}
                    onError={(event) => (event.target.parentElement.style.display = "none")}
                  />
                  {item.game_name}
                </li>
              ))}
            </ul>
            {searchSuggestions.length == 0 && (
              <>
                <span style={{ color: "#fff" }}>{__("No search results")}</span>
                <br />
                <GameSuggestion {...props} categoryName="popular" />
              </>
            )}

            <div className="quick_cateogry_link_area">
              <div className="quick_category_link_heading">{__("Quick Link")}</div>
              <ul>
                <li>
                  <Link
                    onClick={() => {
                      props.setSelectedProviders([]);
                    }}
                    to="/games/slots"
                  >
                    {__("Slots")}
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      props.setSelectedProviders([]);
                    }}
                    to="/games/virtual-games"
                  >
                    {__("Virtual Games")}
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      props.setSelectedProviders([]);
                    }}
                    to="/games/live-casino"
                  >
                    {__("Live Casino")}
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      props.setSelectedProviders([]);
                    }}
                    to="/games/popular"
                  >
                    {__("Popular")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};
export default SearchBox;
