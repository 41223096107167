import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";
import api from "../services/api";
import auth from "../services/auth";

export default function ProvablyFair(props) {
  const params = useParams();
  const [pageContent, setPageContent] = useState("");
  const getContentPage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "provably_fair",
    });

    if (response.status == 200) {
      setPageContent(response.data);
    }
  };

  useEffect(async () => {
    getContentPage();
  }, []);
  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <section className="about content_page_area">
      <div className="privacy_policy">
        <div className="terms_content">
          <div className="page_title">
            {pageContent && pageContent.content_data.page_title}
          </div>
         
          <div className="paragraph">
            {pageContent && renderHTML(pageContent.content_data.content)}
          </div>
     
        </div>
      </div>
    </section>
  );
}
