import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "services/auth";
import GameItem from "elements/games_elements/GameItem";
import ls from "local-storage";
import AppContext from "contexts/AppContext";
import api from "services/api";
import { Button } from "react-bootstrap";
import Flickity from "react-flickity-component";
import Slider from "react-slick";
import { __ } from "services/Translator";

const GameSlider = (props) => {
  // const menuOpen = props.menuOpen;

  const flkty = useRef(null);

  const nextLoad = () => {
    // flkty.current.next();
    sliderRef.current.slickNext();
  };

  const prevLoad = () => {
    // flkty.current.previous();
    sliderRef.current.slickPrev();
  };

  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [categoryName, setCategoryName] = useState(props.categoryName);
  const [gameListsData, setGameListsData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(18);
  const [gameType, setGameType] = useState("");

  const getGames = async () => {
    var response = await api.post("/games/by-category", {
      per_page: perPage,
      page: page,
      game_types: [gameType],
      // specific_games: props.specificGames,
    });
    setGameListsData([]);
    if (response.status === 200 && response.data) {
      // setPaginationData(response.data.paginationData);
      // setPageActivate(page);

      if (gameType == "popular") {
        setGameListsData(response.data.games.popular.gamelist);
      } else if (gameType == "table_games") {
        setGameListsData(response.data.games.table_games.gamelist);
      } else if (gameType == "video_slots") {
        setGameListsData(response.data.games.video_slots.gamelist);
      } else if (gameType == "video_poker") {
        setGameListsData(response.data.games.video_poker.gamelist);
      } else if (gameType == "live_casino") {
        setGameListsData(response.data.games.live_casino.gamelist);
      } else if (gameType == "jackpot") {
        setGameListsData(response.data.games.jackpot.gamelist);
      }
    }
  };

  useEffect(async () => {
    if (categoryName == "New Games") {
      setGameType("popular");
    } else if (categoryName == "Table Games") {
      setGameType("table_games");
    } else if (categoryName == "Slots") {
      setGameType("video_slots");
    } else if (categoryName == "Jackpot Games") {
      setGameType("jackpot");
    } else if (categoryName == "Poker Games") {
      setGameType("video_poker");
    } else if (categoryName == "Live Casino") {
      setGameType("live_casino");
    }
    setPage(1);
  }, [categoryName]);

  useEffect(async () => {
    if (gameType) {
      getGames();
    }
  }, [gameType]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: true,
    autoplaySpeed: 10000,
    arrows: false,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    responsive: [
      // {

      //   breakpoint: 2199,
      //   settings: {
      //     slidesToShow: 6,
      //     slidesToScroll: 6,
      //   }

      // },
      // {

      //   breakpoint: 1800,
      //   settings: {
      //     slidesToShow: 5,
      //     slidesToScroll: 5,
      //   }

      // },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const sliderRef = useRef();

  return (
    <>
      <section className="category_games flickity_games">
        <div className="category_games_header">
          <h4 className="game_headline">{__(categoryName)}</h4>
          <div className="slider_controlls">
            <Button onClick={prevLoad} className="prev">
              <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <title>left</title>
                <path d="M12.586 27.414l-10-10a2 2 0 0 1 0-2.828l10-10a2 2 0 1 1 2.828 2.828L8.828 14H28a2 2 0 1 1 0 4H8.828l6.586 6.586c.39.39.586.902.586 1.414s-.195 1.024-.586 1.414a2 2 0 0 1-2.828 0z"></path>
              </svg>
            </Button>
            <Button onClick={nextLoad} className="prev next">
              <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <title>{__("right")}</title>
                <path d="M19.414 27.414l10-10a2 2 0 0 0 0-2.828l-10-10a2 2 0 1 0-2.828 2.828L23.172 14H4a2 2 0 1 0 0 4h19.172l-6.586 6.586c-.39.39-.586.902-.586 1.414s.195 1.024.586 1.414a2 2 0 0 0 2.828 0z"></path>
              </svg>
            </Button>
          </div>
        </div>

        <Slider ref={sliderRef} {...settings}>
          {gameListsData?.map((game, index) => (
            <GameItem game={game} isAuthenticated={props.isAuthenticated} key={index} {...props} />
          ))}
        </Slider>
      </section>
    </>
  );
};

export default GameSlider;
