import { Accordion } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SelectBox from "elements/SelectBox";
import InputBox from "elements/InputBox";
import Pagination from "elements/Pagination";
import DateSelector from "elements/DateSelector";
import NoRecordsFound from "elements/NoRecordsFound";
import DOMPurify from "dompurify";
import { Button, Modal } from "react-bootstrap";
import api from "services/api";
import { __ } from "services/Translator";

export default function Messages(props) {
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };
  const [loadingMessages, setLoadingMessages] = useState(false);

  const getResults = async () => {
    props.setSpinner(true);
    setLoadingMessages(true);
    var response = await api.post("/player/messages-and-notifications", form);
    props.setSpinner(false);
    setLoadingMessages(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  const deleteItem = async (item) => {
    if (window.confirm("Confirm deletion?")) {
      var deleteUrl = item.type === "message" ? "/player/message/delete" : "/player/notification/delete";
      var response = await api.post(deleteUrl, {
        id: item.id,
      });
      props.showAlert(null, response.data.message, response.status !== 200);
      getResults();
    }
  };

  const [fullMessage, setFullMessage] = useState(null);
  const showItem = async (item) => {
    setFullMessage(item);
    if (item.type === "message") {
      var response = await api.post("/player/message/mark-read", {
        id: item.id,
      });
      if (response.status !== 200) {
        props.showAlert(null, response.data.message, true);
      }
      getResults();
    }
  };

  return (
    <div className="dashboard_content history_area_full">
      <div className="right_area table_area p-3">
        <div className="left_area">
          <h3 className="tabName">{__("Messages")}</h3>
        </div>
        <div className="d-block player_message_container">
          {results.length ? (
            <>
              <ul className="notifications-list">
                {results.map((val, index) => (
                  <React.Fragment key={index}>
                    {val.type === "message" ? (
                      <li className="message-item">
                        <div className="message-from">
                          <b>{__("From")}: </b>
                          {val.data.sender_name} {__("on")} {val.data.added_time}
                        </div>
                        <h4>
                          {__("Subject")}:{val.data.message_subject}
                          {!val.data.message_was_read && (
                            <>
                              <i className="fa fa-envelope unread_mark"></i>
                            </>
                          )}
                        </h4>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(val.data.message_body.substr(0, 150)),
                          }}
                        ></p>

                        <Link to="#" className="open-modal read_more_btn white" onClick={() => showItem(val)}>
                          {__("Read More")}
                        </Link>

                        <Link to="#" onClick={() => deleteItem(val)} className="delete_msg">
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </Link>
                      </li>
                    ) : (
                      <li className="notification-item white">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(val.data.notification_str),
                          }}
                        ></p>
                        <Link to="#" className="open-modal read_more_btn white" onClick={() => showItem(val)}>
                          {__("Read More")}
                        </Link>
                        <div className="notification-time">{val.data.notification_time}</div>

                        <a href="#" className="delete_notification" onClick={() => deleteItem(val)}>
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </a>
                      </li>
                    )}
                  </React.Fragment>
                ))}
              </ul>

              <div className="pagination">
                <Pagination data={pagination} onPageChange={onPageChange} />
              </div>
            </>
          ) : loadingMessages ? (
            <NoRecordsFound content={__("Loading messages") + "..."} />
          ) : (
            <NoRecordsFound />
          )}
        </div>

        <br />
      </div>

      {fullMessage && (
        <Modal className="modal" show={fullMessage} size="lg" onHide={() => setFullMessage(null)}>
          {fullMessage.type === "message" ? (
            <>
              <Modal.Body className="player_message_modal">
                <div onClick={() => setFullMessage(null)} className="modal_close">
                  <i className="fal fa-times"></i>
                </div>

                <div className="modal_headline">{__("Message")}</div>
                <div className="message-from">
                  <b>{__("From")}: </b>
                  {fullMessage.data.sender_name} {__("on")} {fullMessage.data.added_time}
                </div>
                <strong>
                  {__("Subject")}:{fullMessage.data.message_subject}
                </strong>
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(fullMessage.data.message_body),
                  }}
                ></p>
              </Modal.Body>
            </>
          ) : (
            <>
              <Modal.Body className="player_message_modal">
                <div onClick={() => setFullMessage(null)} className="modal_close">
                  <i className="fal fa-times"></i>
                </div>

                <div className="modal_headline">{__("Notification")}</div>
                <div className="message-from">
                  <b>{__("Date")}: </b>
                  {fullMessage.data.notification_time}
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(fullMessage.data.notification_str),
                  }}
                ></p>
              </Modal.Body>
            </>
          )}
        </Modal>
      )}
    </div>
  );
}
