import React, { useState, useEffect } from "react";
import api from "services/api";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Collapse } from "react-bootstrap";
import Avatar from "react-avatar";

import { __ } from "services/Translator";

import VipLevelText, { NextVipLevelText } from "elements/VipLevelText";
export default function UserLeftArea(props) {
  const section = props.section;
  // const __ = (text) => text;
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    currency: "",
  });

  const getProfile = async () => {
    var response = await api.get("/profile");
    if (response.status === 200) {
      setForm(response.data);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  const [collapsesOpen, setCollapsesOpen] = useState({
    progress: false,
    userMenu: true,
  });

  const toggleCollapse = (ev) => {
    var id = ev.target.dataset.collapse;
    setCollapsesOpen({ ...collapsesOpen, [id]: !collapsesOpen[id] });
  };

  const accountProgress = props.accountProgress;

  const [profileAvatarUploadFileName, setProfileAvatarUploadName] = useState();
  const [profileAvatarUploadFile, setProfileAvatarUploadFile] = useState();

  const profileAvatarUpload = (event) => {
    setProfileAvatarUploadName(event.target.files[0].name);
    setProfileAvatarUploadFile(event.target.files[0]);
    profileAvatarUploadAction();
  };

  const profileAvatarUploadAction = async (ev) => {
    const formData = new FormData();
    // Update the formData object

    formData.append("file", ev.target.files[0]);
    var response = await api.post("/profile/avatar-upload", formData);

    if (response.status == 200) {
      setProfileAvatarUploadFile("");
      props.showAlert(__("Success"), response.data.message);
      getProfile();
    } else {
      props.showAlert(__("Error!!"), response.data.message, true);
    }
  };

  return (
    <div className="left_area">
      <h3 className="tabName">
        {__(section)}
        {/* <img
          className="level_img"
          src={require("assets/images/icons/level.png").default}
        /> */}
      </h3>

      <div className="welcome_text">
        {__("Hi")}, {form.firstName}. {__("Welcome to your Dashboard")}.
      </div>

      <div className="user_index_area">
        <div className="user_avatar_area">
          <label for="profileAvatarUpload">
            <img
              alt=""
              className="pencilIcons"
              src={require("../../assets/images/icons/pencil-icon-white.svg").default}
            />
            <Avatar
              size={100}
              name={(form.firstName ? form.firstName : "No") + " " + (form.lastName ? form.lastName : "Name")}
              src={form.playerAvatar}
            />
          </label>
          <input id="profileAvatarUpload" onChange={profileAvatarUploadAction} type="file" className="d-none" />
          {/* <CircularProgressbarWithChildren
            className="profile_picture"
            value={props.accountProgress}
            counterClockwise={true}
          >
            <img src={require("assets/images/logos/zino-logo-new.png").default} />
          </CircularProgressbarWithChildren> */}
        </div>
        <div className="user_name">{form.firstName + " " + form.lastName}</div>
        <div className="user_id">
          <label>{__("Casino ID")}: </label> {form.pin}
        </div>
      </div>

      <div className="vip_level_area">
        <a className="has_menu_action" href="javascript:" data-collapse="progress" onClick={toggleCollapse}>
          <i className="fa fa-star"></i>
          {__("VIP Level")} <VipLevelText identity={form.identity} />
          <span className="caret"></span>
          <div className="pbar">
            <div className="progress">
              <div
                className="progress-bar bg-success"
                role="progressbar"
                style={{ width: accountProgress + "%" }}
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          {/* <i className="icon fas fa-angle-down"></i> */}
        </a>
        <Collapse in={collapsesOpen.progress}>
          <ul className="next_vip_level">
            <li>
              {__("Next VIP Level")}: <NextVipLevelText identity={form.identity} /> ({parseInt(accountProgress)}%)
            </li>
          </ul>
        </Collapse>
      </div>
    </div>
  );
}
