import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import { __ } from "services/Translator";
import JackpotTicker from "elements/JackpotTicker";

const GameItem = ({
  game,
  isAuthenticated,
  showAlert,
  setModalLoginPropsData,
}) => {
  // const __ = (text) => text;
  const [visible, setVisible] = useState(true);
  const defaultImage =
    require("assets/images/logos/zino-logo-loading.png").default;
  const [gameSnapshotUrl, setGameSnapshotUrl] = useState(defaultImage);
  const [processed, setProcessed] = useState(false);

  const hideNoImageFoundGames = () => {
    setVisible(false);
  };

  const [isFavorite, setIsFavorite] = useState(game.is_favorite_game);

  const loadGameImage = () => {
    setGameSnapshotUrl(game.game_snapshot_url);
  };

  const toggleFavoriteGame = async () => {
    var response = await api.post("/toggle-favorite-game", { gameId: game.id });
    if (response.status === 200) {
      if (response.data.status === "added") {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  };

  useEffect(() => {
    setVisible(true);
    setGameSnapshotUrl(defaultImage);
    setIsFavorite(game.is_favorite_game);
    setProcessed(false);
  }, [game.id, isAuthenticated]);

  const fixImageRatio = (event) => {
    if (!processed) {
      setGameSnapshotUrl(game.game_snapshot_url);
      var img = event.target;
      var width = img.width;
      var height = width * 1;
      img.height = height;
      img.className = "";
      setProcessed(true);
    }
  };

  useEffect(() => {
    // alert(isAuthenticated);
  }, []);

  return (
    <div className={"single_game " + (visible ? "" : "d-none")}>
      <div className="games_image">
        <img
          className="loading"
          src={gameSnapshotUrl}
          onError={hideNoImageFoundGames}
          onLoad={fixImageRatio}
        />
        {isAuthenticated && (
          <Link className="gamePlayBtn" to={"/play/" + game.slug}>
            {__("Play")}
          </Link>
        )}

        {!isAuthenticated && (
          <Link
            className="gamePlayBtn"
            onClick={() => {
              // showAlert(__("Attention!!"), __("You need to log in first to play this game."), true);
              setModalLoginPropsData(true);
              setTimeout(function () {
                setModalLoginPropsData(false);
              }, 500);
            }}
          >
            {__("Play")}
          </Link>
        )}
      </div>
      {game.has_jackpot != 0 && game.jackpot_amount != 0 && (
        <div className="jackpot_amount">
          <JackpotTicker
            width={15}
            height={20}
            jackpotAmount={game.jackpot_amount_not_formatted}
          />
        </div>
      )}

      <div className="game_overlay">
        <Link className="game_player_btn" to={"/play/" + game.slug}>
          <img
            alt=""
            src={
              require("../../assets/images/icons/play-button-green.svg").default
            }
          />
          <img
            alt=""
            src={
              require("../../assets/images/icons/play-button-blue.svg").default
            }
          />
        </Link>

        {/* <div className="game_name white">{game.game_name}</div> */}
        <div className="game_footer">
          {/* <div className="provider_name">{game.provider?.name ?? "N/A"}</div> */}
          {isAuthenticated && (
            <Link className="game_player_btn " to={"/play/" + game.slug}>
              <img
                alt=""
                className="greenBtn"
                src={
                  require("../../assets/images/icons/play-button-green.svg")
                    .default
                }
              />
              <img
                alt=""
                className="blueBtn"
                src={
                  require("../../assets/images/icons/play-button-blue.svg")
                    .default
                }
              />
            </Link>
          )}

          {!isAuthenticated && (
            <Link
              className="game_player_btn d-none"
              onClick={() => {
                showAlert(
                  __("Attention!!"),
                  __("You need to log in first to play this game."),
                  true
                );
              }}
            >
              <img
                alt=""
                className="greenBtn"
                src={
                  require("../../assets/images/icons/play-button-green.svg")
                    .default
                }
              />
              <img
                alt=""
                className="blueBtn"
                src={
                  require("../../assets/images/icons/play-button-blue.svg")
                    .default
                }
              />
            </Link>
          )}
        </div>
        <div className="games_rating d-none">
          <div className="star">
            {/* RTP:{" "}
          <span className="white">{game.rtp ? game.rtp + "%" : "N/A"}</span> */}
            {isAuthenticated && (
              <div onClick={toggleFavoriteGame} className="games_badge">
                <i className={(isFavorite ? "fas" : "far") + " fa-heart"}></i>
              </div>
            )}
          </div>
          {/* <div className="view">
          <i className="fas fa-eye"></i>{" "}
          <span className="count">{game.views}</span>
        </div> */}
          <div className="provider_name white">
            {game.provider?.name ?? "N/A"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameItem;
