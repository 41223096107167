// var configEnv = "development";
// var configEnv = "staging";
var configEnv = "production";

var config = {
  //##########Live config##########
  production: {
    appUrl: "https://zinocasino.com",
    apiUrl: "https://api.zinocasino.com/api/v1",
    staticUrl: "https://api.zinocasino.com/api/v1",
    liveChatUrl: "https://bossoffice.zinocasino.com/public/livechat/php/app.php?widget-inline",
    debug: false,
    fileSourceUrl: "https://bossoffice.zinocasino.com/public",
  },

  //#########Stage config##########
  staging: {
    appUrl: "https://zino.cbstagings.com",
    apiUrl: "https://zinoapi.cbstagings.com/api/v1",
    staticUrl: "https://zinoapi.cbstagings.com/api/v1",
    liveChatUrl: "https://zinoboss.cbstagings.com/public/livechat/php/app.php?widget-inline",
    fileSourceUrl: "https://zinoboss.cbstagings.com/public",
    debug: false,
  },

  //##########Local config##########
  development: {
    appUrl: "http://localhost:3000",
    apiUrl: "http://casinoapi.local.host/api/v1",
    staticUrl: "http://casinoapi.local.host/api/v1",
    liveChatUrl: "http://casinoadmin.local.host/public/livechat/php/app.php?widget-inline",
    fileSourceUrl: "http://casinoadmin.local.host/public",
    debug: true,
  },
};

var finalConfig = config[configEnv];

finalConfig.defaultLanguage = "es";

finalConfig.languages = {
  en: {
    code: "en",
    language: "English",
    flag_path: finalConfig.fileSourceUrl + "/images/flags/svg/gb.svg",
  },
  es: {
    code: "es",
    language: "Español",
    flag_path: finalConfig.fileSourceUrl + "/images/flags/svg/es.svg",
  },
  pt: {
    code: "pt",
    language: "Português",
    flag_path: finalConfig.fileSourceUrl + "/images/flags/svg/pt.svg",
  },
};

export default finalConfig;
