import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import SelectBox from "elements/SelectBox";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import moneyFormatter from "services/moneyFormatter";
import ls from "services/ls";
import UserLeftArea from "elements/dashboard_elements/UserLeftArea";
import { isMobile } from "services/isMobile";
import { __ } from "services/Translator";
import { Modal, Button, Alert, Dropdown, Form, Overlay, Tooltip, Accordion, ProgressBar } from "react-bootstrap";

import FiatCashier from "elements/dashboard_elements/FiatCashier";

export default function Cashier(props) {
  // const __ = (text) => text;
  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const userBalance = props.userBalance;

  const [selectedWallet, setSelectedWalelt] = useState(null);
  const [selectItems, setSelectItems] = useState([]);

  const [deleteBonusModalOpen, setDeleteBonusModalOpen] = useState(false);

  const doDeleteBonuses = async () => {
    props.setSpinner(true);
    var response = await api.post("/delete-bonus");
    if (response.status === 200) {
      props.setSpinner(false);
      toggleDeleteBonusModalOpen();
      props.getUserBalance();
      props.showAlert("Warning!!", response.data.message);
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  const toggleDeleteBonusModalOpen = () => {
    setDeleteBonusModalOpen(!deleteBonusModalOpen);
  };

  useEffect(() => {
    if (props.userBalance.wallets.length) {
      setSelectedWalelt(props.userBalance.wallets[0].currency);
      setSelectItems(
        props.userBalance.wallets.map((item) => {
          return { label: item.currency, value: item.value };
        })
      );
    }
  }, [props.userBalance]);

  const today = new Date().getFullYear() + "-" + new Date().getMonth() + "-" + new Date().getDate();

  const checkIframe = () => {
    if (window.parent.parent.location.href != window.location.href) {
      window.parent.location.href = window.location.href;
    } else {
    }
  };

  useEffect(async () => {
    checkIframe();
  }, []);

  const BonusArea = () => (
    <div className="bonus_intro">
      <div className="intro_title">
        {__("Bonus Balance")}: {"$" + props.userBalance.bonus_balance}
      </div>
      <div className="intro_title">
        {__("Bonus Unlock Progress")}: ({"$" + props.userBalance.wagering_requirements_achieved}/
        {"$" + props.userBalance.wagering_requirements_total})
        <div className="gameLoadingProgress wagerRequirementBar">
          <div className="gameLoadingInfo">
            {__("Achieved")} {props.userBalance.wagering_requirements_achieved} {__("of")}{" "}
            {props.userBalance.wagering_requirements_total} {__("Wagering Requirements")}
          </div>
          <ProgressBar
            variant="success"
            label={Math.round(props.userBalance.wagering_percentage) + "%"}
            now={props.userBalance.wagering_percentage}
          />
        </div>
      </div>
      <Link to="#" onClick={toggleDeleteBonusModalOpen} className="common_btn  no_margin">
        {__("Delete Bonus")}
      </Link>
    </div>
  );

  const BalanceProgress = () => (
    <div className="progress_chart_area">
      <div className="progress_content">
        <div className="content_title">
          {__("Balance")}
          <img src={require("../../assets/images/icons/mastercard.png").default} />
        </div>
        <div className="content_details">
          <div className="text_section">
            <h3 className="balance">{"$" + props.userBalance.total_balance}</h3>
            <div className="date_details">
              <div className="paragraph no_margin white">{__("Current Date")}</div>
              <div className="date_value">{today}</div>
            </div>
          </div>
          <div className="progress_section">
            <CircularProgressbarWithChildren
              className="progress_container"
              value={props.userBalance.total_balance}
              counterClockwise={true}
            >
              <div className="progress_value">{"$" + props.userBalance.total_balance}</div>
            </CircularProgressbarWithChildren>
          </div>
        </div>
      </div>
      <div className="progress_content chart_area">
        <div className="content_title">{__("Cash Balance")}</div>
        <div className="content_details">
          <div className="text_section">
            <h3 className="balance">{"$" + props.userBalance.cash_balance}</h3>
          </div>
        </div>
      </div>
    </div>
  );

  const BalanceProgressMobile = () => (
    <div className="progress_chart_area mobile">
      <div className="progress_content">
        <div className="content_title">{__("Cash Balance")}</div>
        <div className="content_details">
          <div className="text_section">
            <h3 className="balance">{"$" + props.userBalance.cash_balance}</h3>
            <div className="date_details">
              <div className="paragraph no_margin white">{__("Current Date")}</div>
              <div className="date_value">{today}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="progress_content chart_area">
        <div className="content_title">{__("Bonus Balance")}</div>
        <div className="content_details">
          <div className="text_section">
            <h3 className="balance">{"$" + props.userBalance.bonus_balance}</h3>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="dashboard_content">
      {!props.isMobileDeviceByViewPort && <UserLeftArea section="Cashier" {...props} />}
      <div className="right_area">
        {!props.isMobileDeviceByViewPort ? (
          <>
            <BalanceProgress />
            <BonusArea />
            <br />
            <br />
            <FiatCashier {...props} />
          </>
        ) : (
          <>
            <FiatCashier {...props} />
            <br />
            <BonusArea />
            <br />
            <br />
            <BalanceProgressMobile />
          </>
        )}
        <br />
      </div>

      <Modal show={deleteBonusModalOpen} onHide={toggleDeleteBonusModalOpen}>
        <Modal.Body className="cashier_modal">
          <div onClick={toggleDeleteBonusModalOpen} className="modal_close">
            <i className="fal fa-times"></i>
          </div>

          <div className="modal_headline">{__("Are you sure to delete your bonus balance?")}</div>

          <div className="row">
            <div className="col-6 col-md-4 offset-md-2 col-lg-3 offset-lg-3">
              <button type="button" className="common_btn" onClick={toggleDeleteBonusModalOpen}>
                {__("No")}
              </button>
            </div>
            <div className="col-6 col-md-4 col-lg-3">
              <button type="button" className="common_btn" onClick={doDeleteBonuses}>
                {__("Yes")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
